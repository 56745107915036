import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationEventBus} from '../../notification.event.bus';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {

    dragging = false;
    loaded = false;
    imageLoaded = false;

    @Input() imageSrc = '../../assets/img/default-avatar.png';
    @Output() imageChanged = new EventEmitter();
    @Output() cancel = new EventEmitter();
    imageFile: File;
    @Input() isDisabled: boolean;
    notificationMessages: { title: string; titleDes: string };

    constructor(private notificationsManager: NotificationEventBus, private translate: TranslateService) {
    }

    ngOnInit() {
    }

    initMessages() {
        this.translate.stream('imgUploader').subscribe((res) => {
            this.notificationMessages = {
                'title': res['title'],
                'titleDes': res ['titleDes']
            };
        });
    }

    handleDragEnter() {
        this.dragging = true;
    }

    handleDragLeave() {
        this.dragging = false;
    }

    handleDrop(e) {
        e.preventDefault();
        this.dragging = false;
        this.handleInputChange(e);
    }
    onCancelModel(){
        this.cancel.emit(true);
    }

    handleImageLoad() {
        this.imageLoaded = true;
    }

    handleInputChange(e) {
        this.imageFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        const pattern = /image-*/;
        let reader = new FileReader();

        if (!this.imageFile.type.match(pattern)) {
            // this.notificationsManager.showErrorNotification(this.notificationMessages.title, this.notificationMessages.titleDes, true , false , false , 100)
            return;
        }

        this.loaded = false;

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(this.imageFile);
        this.imageChanged.emit(this.imageFile);
    }

    _handleReaderLoaded(e) {
        let reader = e.target;
        this.imageSrc = reader.result;
        this.loaded = true;
    }


}
