export class AlertModel {
  type: string;
  text: string;
  show: boolean;


  constructor(type: string, text: string) {
    this.type = type;
    this.text = text;
    this.show = false;
  }
}
