import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {INSAuthResponse} from '../models/responce/ins.auth.response';
import {INSException} from '../models/ins.exception';
import {AuthCredentials} from '../models/authdata/auth.credentials';
import {Observable} from 'rxjs';
import {takeWhile, map, catchError} from 'rxjs/operators';
import {INSMessage} from '../models/ins.message';
import {INSResponse} from '../models/responce/ins.response';
import {AppConstants} from '../app.constants';
import {AppAuthGuard} from '../app.authguard';

@Injectable({
    providedIn: 'root'
})
export class InternalAuthService extends BaseService {


    constructor(http: HttpClient ,authGuard: AppAuthGuard) {
        super(http , authGuard);
    }

    checkUsernameExistence(successCallback: (insAuthResponce: INSResponse<any>) => void,
                           errorCallBack: (insException: INSException) => void,
                           email: string) {
        this.checkUsernameExistenceLocal(email).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallBack(new INSException(error))
        );
    }

    getAuth(successCallback: (insAuthResponce: INSAuthResponse) => void,
            errorCallBack: (insException: INSException) => void,
            signinCredentials: AuthCredentials) {
        this.getAuthLocal(signinCredentials).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(res),
            error => errorCallBack(new INSException(error))
        );
    }

    private getAuthLocal(signinCredentials: AuthCredentials): Observable<INSAuthResponse> {
        console.log(signinCredentials);
        const apiURL = 'auth/token';
        return this.post<INSAuthResponse>(apiURL, signinCredentials).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private checkUsernameExistenceLocal(email: string): Observable<INSResponse<any>> {

        const apiURL = 'users/existence?username=' + email;

        return this.get(apiURL, null);
    }


    getTwitterSignInUrl(successCallback: (message: string) => void, errorCallback: (insException: INSException) => void, userId: string): any {
        this.getTwitterSignInUrlLocal(userId).pipe(takeWhile(() => true)).subscribe(
            res => successCallback(res.message),
            error => errorCallback(new INSException(error))
        );
    }

    getInstaSignInUrl(userId : string) {
        let redirectUri = this.resolveAPIBase() + '/social-media/instagram/consent/callback/';
        let apiURL = 'https://api.instagram.com/oauth/authorize/?client_id=' + 'a815632a6fa04c628f2a5ee6449f0017' + '&redirect_uri=' + redirectUri + '&response_type=code';
        return apiURL;
    }

    getGoogleLoginInUrl() {
        let redirectUri = this.resolveAPIBase() + '/social-media/Google/consent/callback/';
        let apiURL = 'https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/plus.login&client_id=' + '218622594125-o5plkkmteqnqdcbjbjc0mkiforhbirel.apps.googleusercontent.com' + '&redirect_uri=' + redirectUri + '&response_type=code';
        // https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/plus.login&response_type=code&redirect_uri=http://localhost:4200&client_id=218622594125-o5plkkmteqnqdcbjbjc0mkiforhbirel.apps.googleusercontent.com
        return apiURL;
    }


    private getTwitterSignInUrlLocal(userId: string): Observable<INSMessage> {
        let apiURL = 'social-media/twitter/consent/url';
        return this.get(apiURL, null);
    }


    resolveResponse(data: any): INSAuthResponse {
        this.httpResponse = data;
        return data;
    }

    public getAccessToken(): string {
        return sessionStorage.getItem(AppConstants.ACCESS_TOKEN);
    }

}
