import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {INSUsersApp} from '../models/app/ins.users.app';
import {INSResponse} from '../models/responce/ins.response';
import {Observable} from 'rxjs';
import {INSException} from '../models/ins.exception';
import {takeWhile, map} from 'rxjs/operators';
import {INSMessage} from '../models/ins.message';
import {UserConsentGrant} from '../models/responce/userConsentGrant';
import {AppAuthGuard} from '../app.authguard';

@Injectable({
    providedIn: 'root'
})
export class AppManagementService extends BaseService {

    constructor(http: HttpClient ,authGuard: AppAuthGuard) {
        super(http , authGuard);
    }

    getApp(successCallback: (insApp: INSUsersApp) => void,
           errorCallback: (insException: INSException) => void, app_key: string) {
        this.getAppLocal(app_key).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    getTransactionToken(successCallback: (insApp: any) => void,
                        errorCallback: (insException: INSException) => void, app_key: string, app_secret: string) {
        this.getTransactionTokenLocal(app_key, app_secret).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(res),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    grantConsentForApp(successCallback: (insApp: any) => void,
                        errorCallback: (insException: INSException) => void, userConsentGrant: UserConsentGrant) {
        this.grantConsentForAppLocal(userConsentGrant).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    regenerateSecret(successCallback: (insApp: any) => void,
                     errorCallback: (insException: INSException) => void, app_key: string) {
        this.regenerateSecretLocal(app_key).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    private getAppLocal(app_key: string): Observable<INSResponse<INSUsersApp>> {
        const apiUrl = 'apps/' + app_key;
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    private getTransactionTokenLocal(app_key: string, app_secret: string): Observable<INSResponse<any>> {
        const apiUrl = 'auth/token/transactions';
        let body = {
            'appKey': app_key,
            'appSecret': app_secret
        };
        return this.postWithoutAuth(apiUrl, body, new HttpParams());
    }

    private grantConsentForAppLocal(consent: UserConsentGrant): Observable<INSResponse<any>> {
        const apiUrl = 'consents';

        return this.postWithoutAuth(apiUrl, consent, new HttpParams());
    }

    private regenerateSecretLocal(app_key: string): Observable<INSResponse<any>> {
        const apiUrl = 'apps/' + app_key + '/app-secret/requests';
        return this.postWithAuth(apiUrl, null, new HttpParams());
    }

    getApps(successCallback: (message: INSUsersApp[]) => void, errorCallback: (insException: INSException) => void) {
        this.getAppsLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.createApps(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    private getAppsLocal(): Observable<INSResponse<INSUsersApp>> {
        const apiUrl = 'apps';
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    saveApp(successCallback: (message: INSMessage) => void,
            errorCallback: (insException: INSException) => void, insApp: INSUsersApp) {
        this.saveAppLocal(insApp).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error))
        );
    }

    changeAppStatus(successCallback: (message: INSMessage) => void,
                    errorCallback: (insException: INSException) => void, appKey: string, status: boolean) {
        this.changeAppStatusLocal(appKey, status).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(this.makeError(error))
        );
    }

    private saveAppLocal(insApp: INSUsersApp): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'apps';
        return this.postWithAuth<INSUsersApp>(apiUrl, insApp, new HttpParams()).pipe(
            map(d => this.resolveResponse(d)),
        );
    }

    private changeAppStatusLocal(appKey: string, status: boolean): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'apps/' + appKey + '/active-status';
        const params = new HttpParams().set('shouldDisable', status.toString());
        return this.putWithAuth<any>(apiUrl, null, params).pipe(
            map(d => this.resolveResponse(d)),
        );
    }

    updateApp(successCallback: (message: INSMessage) => void,
              errorCallback: (insException: INSException) => void,
              insApp: INSUsersApp, app_key: string) {
        this.updateAppLocal(insApp, app_key).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(this.makeError(error))
        );
    }

    private updateAppLocal(insApp: INSUsersApp, app_key: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'apps/' + app_key;
        return this.putWithAuth<INSUsersApp>(apiUrl, insApp, new HttpParams()).pipe(
            map(d => this.resolveResponse(d)),
        );
    }

    deleteApp(successCallback: (message: INSMessage) => void,
              errorCallback: (insException: INSException) => void, app_key: string) {
        this.deleteAppLocal(app_key).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(this.makeError(error))
        );
    }

    private deleteAppLocal(app_key: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'apps/' + app_key;
        return this.deleteWithAuth<INSUsersApp>(apiUrl, new HttpParams()).pipe(
            map(d => this.resolveResponse(d)),
        );
    }


    /* ****************************** */
    private createApp(res: INSResponse<INSMessage>) {
        return res.results[0];
    }

    private createApps(res: INSResponse<INSUsersApp>): INSUsersApp[] {
        console.log(res);
        return res.results;
    }

    resolveResponse(data: any): INSResponse<INSUsersApp> {
        this.httpResponse = data;
        return data;
    }
}
