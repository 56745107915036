export class AuthCredentials {
    username: string;
    password: string;
    source: string;
    idToken: string;
    accessToken: string;
    accessTokenSecret: string;

    constructor() {
        this.source = 'INSPIRE_CONNECT';
        this.username = '';
        this.password = '';
        this.idToken = '';
        this.accessToken = '';
        this.accessTokenSecret = '';
    }
}