import {Component, OnInit, ViewChild, ElementRef, NgZone} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WelcomeEventBus} from '../welcome.event.bus';
import {LoaderService} from '../../../services/loader.service';
import {Router} from '@angular/router';
import {AppConstants} from '../../../app.constants';
import {INSException} from '../../../models/ins.exception';
import {INSMessage} from '../../../models/ins.message';
import {AlertModel} from '../../../models/internal/alert.model';
import {InternalAuthService} from '../../../services/auth.service';
import {UserManagementService} from '../../../services/user.management.service';
import {NotificationEventBus} from '../../../notification.event.bus';
import {ValidationsManager} from '../../../utill/validations/validations.manager';
import {INSAuthResponse} from '../../../models/responce/ins.auth.response';
import {AuthCredentials} from '../../../models/authdata/auth.credentials';
import {isNullOrUndefined} from 'util';
import {INSUserProfile, ScopeDataBlocks} from '../../../models/user/ins.user.profile';
import {AppAuthGuard} from '../../../app.authguard';
import {ConnectedProfiles, ProfileBuildingDet} from '../../../models/profile/connected.profiles';
import {ProfileService} from '../../../services/profile.service';
import {INSSocialMediaType} from '../../../models/profile/ins.social.media.type';


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

    signUpForm: FormGroup;
    getEmailForm: FormGroup;
    emailResendForm: FormGroup;
    showEmail: boolean;
    tempAccessToken: string;
    facebookUserData: any;
    googleUserData: any;
    nextBtnTxt: string = 'Next';
    userCredentials: AuthCredentials;
    displayMode = 0; // 0=>username ; 1=>password; 2=> verification code ; 3=> resend email; 4=>change email
    user = {
        'username': '',
        'password': ''
    };
    formErrors = {
        'username': '',
        'password': '',
        'email': '',
        'verificationCode': ''
    };
    validationMessages = {
        'email': {
            'required': 'Email required.',
            'pattern': 'Invalid email.',
        },
        'username': {
            'required': 'Username required.',
            'pattern': 'Invalid username.',
        },
        'verificationCode': {
            'required': 'Verification code required.',
        },
        'password': {
            'required': 'Password required.',
            'minlength': 'Password should contain at least 8 characters.'
        },

    };
    formErrorsEmail = {
        'username': ''
    };

    @ViewChild('username') userNameFocus: ElementRef;
    @ViewChild('password') passwordFocus: ElementRef;
    @ViewChild('email') emailFocus: ElementRef;
    @ViewChild('verificationCode') verificationCodeFocus: ElementRef;
    @ViewChild('modal')
        // modal: ModalComponent;

    agreementConfirmed = false;
    topWording: string;
    description: string;
    displayUsername: string;
    authUser = new AuthCredentials();
    auth: INSAuthResponse = new INSAuthResponse();
    userEmail: string;

    constructor(private formBuilder: FormBuilder,
                private welcomeEventBus: WelcomeEventBus,
                private router: Router,
                private _ngZone: NgZone,
                private authGuard: AppAuthGuard,
                private profileService: ProfileService,
                private authService: InternalAuthService,
                private validationsManager: ValidationsManager,
                private userManagementService: UserManagementService,
                private notificationEventBus: NotificationEventBus,
                private loaderService: LoaderService) {
        this.user.username = '';
        this.user.password = '';
        this.topWording = 'Sign up';
        this.showEmail = false;
        this.makeSignUpForm();
        this.makeResendForm();
    }

    ngOnInit() {
    }


    onAgreementSelect() {
        this.agreementConfirmed = !this.agreementConfirmed;
    }

    makeSignUpForm() {
        this.signUpForm = this.formBuilder.group(
            {
                username: ['', Validators.required],
                verificationCode: ['', Validators.required],
                password: ['', Validators.compose([<any>Validators.required, Validators.minLength(8)])]
            }
        );
        this.getEmailForm = this.formBuilder.group(
            {
                email: ['', Validators.compose([Validators.required,
                    Validators
                        .pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])]
            }
        );

    }

    onSignUp(signUpUser: AuthCredentials, valid: boolean) {
        this.showInvalidFields();
        if (!this.agreementConfirmed) {
            this.onAgreementSelectedFailed();
        } else if (this.signUpForm.controls.username.valid && this.signUpForm.controls.password.valid && this.agreementConfirmed) {
            let user = new AuthCredentials();
            user.username = signUpUser.username;
            user.password = signUpUser.password;
            this.displayUsername = user.username;
            this.description = 'Hi ' + user.username + '. Give us your email, we will send you the account verification. Don\'t worry, we just want to stay in touch.';
            this.topWording = 'Email';
            this.checkValidFields(user);
            const isEmail = this.validationsManager.validateEmail(signUpUser.username);
            if (isEmail) {
                this.getEmailForm.controls.email.setValue(user.username);
            }
        } else {
            this.loaderService.hideLoader();
        }
    }

    onAddEmail(email: any, valid: boolean) {
        if (valid) {
            this.verifyWithEmail(email.email);
        } else {
            this.showInvalidEmail();
        }
    }

    onPasswordFocus() {
        if (this.signUpForm.controls.username.status !== 'INVALID') {
            this.checkUsernameExistence(this.signUpForm.controls.username.value);
        }
    }

    checkUsernameExistence(email: string) {
        this.authService.checkUsernameExistence(
            (ack: any) => {
                if (ack.id.trim() == 'TAKEN') {
                    this.signUpForm.controls.username.setErrors({'incorrect': true});
                    this.signUpForm.controls.username.markAsTouched();
                    this.formErrors.username = 'Username already exists';
                } else {
                    this.formErrors.username = '';

                }

            },
            (insException: INSException) => {
            },
            email);
    }

    checkValidFields(signUpUser: AuthCredentials) {
        if (this.formErrors.username === '' && this.formErrors.password === '') {
            this.signUp(signUpUser);
        }
    }

    signUp(signUpUser: AuthCredentials) {
        this.userManagementService.saveUser(
            (ack: any) => {
                if (ack.idType === 'EMAIL_VERIFICATION') {
                    this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Verification Email has been sent to ' + ack.id));
                    this.router.navigate([AppConstants.WELCOME_ROUTE + '/' + AppConstants.SIGN_IN]);
                    this.loaderService.hideLoader();
                } else {
                    this.getEmailForm.controls.email.setValue(ack.id);
                    this.welcomeEventBus.addSignUpMessage('Email not specified');
                    this.showEmail = true;
                }
                this.tempAccessToken = ack.accessToken.accessToken;

                if (signUpUser.source === 'FACEBOOK') {
                    let facebookLoginUser: ConnectedProfiles = new ConnectedProfiles();
                    facebookLoginUser.email = this.facebookUserData.email;
                    facebookLoginUser.name = this.facebookUserData.name;
                    facebookLoginUser.image = this.facebookUserData.image;
                    facebookLoginUser.provider = this.facebookUserData.provider;
                    facebookLoginUser.lastConnected = new Date();
                    const socialTypeData = new INSSocialMediaType(signUpUser.source, this.facebookUserData.token);
                    let pbDet = new ProfileBuildingDet(socialTypeData, facebookLoginUser);
                    this.sendSocialMediaDetails(pbDet);
                }
            },
            (insException: INSException) => {
                if (insException.message.toLowerCase().includes('existing user')) {

                    if (signUpUser.source !== 'INSPIRE_CONNECT') {
                        this.doOnSignIn(signUpUser);
                    }
                    this.doOnSignIn(signUpUser);
                } else {
                    this.welcomeEventBus.addSignUpMessage(insException.message);
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                }
            },
            signUpUser);


    }

    private sendSocialMediaDetails(socialData: ProfileBuildingDet) {
        this.profileService.linkSocialMediaWithoutAccessToken(
            (ack: INSMessage) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('success', ack.message));
                // this.onSaveProfile();
            },
            (insException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
            socialData, this.tempAccessToken);

    }

    doOnSignIn(signInCredentials: AuthCredentials) {
        this.authService.getAuth(
            (auth: INSAuthResponse) => {
                this.userEmail = auth.message.id;
                auth.provider = signInCredentials.source;
                auth.username = signInCredentials.username;
                this.onSignInSuccess(auth);

            },
            (insException: any) => {
                let msg = this.getErrorMsg(insException.message);
                if (msg.toLowerCase().trim().includes('email not verified')) {
                    insException.message.error.results[0].message = 'Email not verified';
                    this.notificationEventBus.updateAlertContent(new AlertModel('info', 'Verification Email has been sent to ' + msg.split(':')[1]));
                } else if (msg.toLowerCase().includes('email verification request not found')) {
                    this.showEmail = true;
                } else if (msg.toLowerCase().includes('bad credentials')) {
                    this.signUp(signInCredentials);
                } else {
                    this.welcomeEventBus.addSignUpMessage(insException.message);
                }
            },
            signInCredentials);
    }

    onSignInSuccess(auth: INSAuthResponse) {
        if (!isNullOrUndefined(auth.message)) {
            if (auth.message.idType === 'EMAIL_VERIFICATION') {
                this.tempAccessToken = auth.accessToken;
                this.displayMode = 2;
                this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Verification Email has been sent to ' + this.userEmail));
                this.loaderService.hideLoader();
            } else if (auth.message.idType === 'MISSING_EMAIL') {
                this.tempAccessToken = auth.accessToken;
                this.showEmail = true;
                this.loaderService.hideLoader();
            }

        } else {
            this.authGuard.saveCurrentUserAuth(auth);
            if (auth.rememberMe) {
                this.authGuard.saveCurrentUserAuthToLocalStorage(auth);
            }
            this.userManagementService.loadUserProfile(
                (userProfile: ScopeDataBlocks[]) => {
                    this.authGuard.saveCurrentUser(this.makeUserProfile(userProfile));
                    if (auth.rememberMe) {
                        this.authGuard.saveCurrentUserToLocalStorage(this.makeUserProfile(userProfile));
                    }
                    this.notificationEventBus.updateAlertContent(new AlertModel('success', 'signed in'));

                    this.authGuard.redirectToProfile(); // TODO check the role and do redirect
                    this.router.navigate([AppConstants.HOME_ROUTE]);
                },
                (insException: INSException) => {
                    this.welcomeEventBus.addSignUpMessage(insException.message);
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                    this.authGuard.redirectToWelcome();
                });
        }
    }


    makeUserProfile(scopeData: ScopeDataBlocks[]) {
        let userProfile: INSUserProfile = new INSUserProfile();
        for (const data of scopeData) {
            switch (data.scopeCode) {
                case 'FULL_NAME' :
                    userProfile.fullName = data.data;
                    break;
                case 'BIRTHDAY' :
                    userProfile.birthday = data.data;
                    break;
                case 'EMAIL' :
                    userProfile.email = data.data;
                    break;

            }
        }
        return userProfile;
    }

    getErrorMsg(error: any): string {
        return error.error.results[0].message;
    }

    verifyWithEmail(email: string) {
        this.verifyWithAccessToken(email);
    }


    verifyWithAccessToken(email: string) {
        this.userManagementService.saveEmailAndSendVerificationLink(
            (ack: INSMessage) => {
                this.showEmail = true;
                this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Check your mails and confirm your account.'));
                this.router.navigate([AppConstants.WELCOME_ROUTE + '/' + AppConstants.SIGN_IN]);

            },
            (insException: INSException) => {
                this.welcomeEventBus.addSignUpMessage(insException.message);
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
            email, this.tempAccessToken);
    }

    onValueChanged(formName: any, formErrors: any, data?: any) {
        if (!formName) {
            return;
        }
        const form = formName;
        for (const field in formErrors) {
            // clear previous error message (if any)
            formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {

                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    formErrors[field] += messages[key] + ' ';
                }
            }
        }
        return formErrors;
    }

    makeResendForm() {
        this.emailResendForm = this.formBuilder.group(
            {
                username: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
            }
        );
        this.emailResendForm.valueChanges
            .subscribe(data => this.onValueChanged(this.emailResendForm, this.formErrorsEmail, data));
    }

    showInvalidFields() {
        if (this.signUpForm.controls.username.status === 'INVALID') {
            // this.emailFocus.nativeElement.focus();
            document.getElementById('userNameDiv').classList.add('input-text--invalid');
            this.formErrors.username = 'Username Required';
        } else {
            document.getElementById('userNameDiv').classList.remove('input-text--invalid');
            this.formErrors.username = '';
        }
        if (this.displayMode === 0 && this.signUpForm.controls.password.status === 'INVALID') {
            if (isNullOrUndefined(this.signUpForm.controls.password.value) || this.signUpForm.controls.password.value.trim().length === 0) {

                this.formErrors.password = this.validationMessages.password.required;
            } else {
                this.formErrors.password = this.validationMessages.password.minlength;
            }
            document.getElementById('pass').classList.add('input-text--invalid');
        } else {
            document.getElementById('pass').classList.remove('input-text--invalid');
            this.formErrors.password = '';
        }
        if (this.displayMode === 2 && this.signUpForm.controls.verificationCode.status === 'INVALID' && (this.signUpForm.controls.verificationCode.value !== '' || this.signUpForm.controls.verificationCode.touched)) {
            this.passwordFocus.nativeElement.focus();
            document.getElementById('code').classList.add('input-text--invalid');
            this.formErrors.verificationCode = this.validationMessages.verificationCode.required;
        } else {
            if (this.displayMode === 2) {
                try {
                    document.getElementById('code').classList.remove('input-text--invalid');
                } finally {
                    this.formErrors.verificationCode = '';
                }
            }
        }
    }

    getNextBtnTxt(): string {
        return this.nextBtnTxt;
    }

    onNextClicked(): void {
        this.description = 'We sent you an email with the link.';
        this.onVerifyCode(this.signUpForm.value, this.signUpForm.controls.verificationCode.valid);
        this.loaderService.hideLoader();
    }

    onVerifyCode(signInCredentials: AuthCredentials, valid: boolean) {
        if (valid) {
            this.setFinalValidationForCode(this.authUser);
        } else {
            this.showInvalidFields();
        }
    }

    setFinalValidationForCode(signInCredentials: AuthCredentials) {
        if (this.formErrors.verificationCode !== '') {
            this.verificationCodeFocus.nativeElement.focus();
        } else {
            this.doOnVerifyEmail(signInCredentials);
        }
    }

    doOnVerifyEmail(signInCredentials: AuthCredentials) {
        this.userManagementService.verifyEmail(
            (auth: INSMessage) => {
                if (sessionStorage.getItem(AppConstants.PROVIDER) !== 'TWITTER') {
                    this.doOnSignIn(this.authUser);
                } else {
                    this._ngZone.run(() => {
                        this.router.navigate([AppConstants.HOME_ROUTE]);
                    });
                    this.onSignInSuccess(this.auth);
                }
            },
            (insException: any) => {
                if (insException.message.includes('email verification request not found')) {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', 'Invalid verification code.'));
                    this.signUpForm.controls.verificationCode.setValue('');
                    this.verificationCodeFocus.nativeElement.focus();
                }
            },
            this.userEmail, 'inspire-connect', this.signUpForm.controls.verificationCode.value);
    }

    showInvalidEmail() {
        if (this.getEmailForm.controls.email.status === 'INVALID') {
            // this.emailFocus.nativeElement.focus();
            document.getElementById('emailDiv').classList.add('input-text--invalid');
            this.formErrors.email = 'Email Required';
        } else {
            document.getElementById('emailDiv').classList.remove('input-text--invalid');
            this.formErrors.username = '';
        }
    }

    isInvalid(formGroup: FormGroup, formControl?: string): boolean {
        if (formControl === undefined) {
            return !(formGroup.status === 'VALID');
        } else {
            return formGroup.get(formControl).status === 'INVALID' && !formGroup.get(formControl).untouched;
        }
    }

    onAgreementSelectedFailed() {
        // this.notificationEventBus.updateAlertContent(new AlertModel('error', 'Select "I Agree Privacy Policy."'));
        document.getElementById('rememberMe').classList.add('input-text--invalid');
    }

    switchToSignIn() {
        this.router.navigate([AppConstants.WELCOME_ROUTE + '/' + AppConstants.SIGN_IN]);
    }


//    -----------------------------------------------------
    getDisplayMode(displayMode: number) {
        if (displayMode === 4) {
            this.showEmail = true;
        }
    }

    getSigninCredentials(credentials: AuthCredentials) {
        this.userCredentials = credentials;
        this.signUp(credentials);
    }

    getFacebookUser(credentials: any) {
        this.facebookUserData = credentials;
    }

    getGoogleUser(credentials: any) {
        this.googleUserData = credentials;
    }

}
