import {Component, ElementRef, HostListener, NgZone} from '@angular/core';
import {AlertModel} from './models/internal/alert.model';
import {NotificationEventBus} from './notification.event.bus';
import {environment} from '../environments/environment';
import {ValidationsManager} from './utill/validations/validations.manager';
import {AppConstants} from './app.constants';
import {AppAuthGuard} from './app.authguard';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'app';
    alertList: AlertModel[] = [];

    constructor(private notificationEventBus: NotificationEventBus, private  zone: NgZone, private elementRef: ElementRef, private validationManager: ValidationsManager, private appAuthGuard: AppAuthGuard) {
        this.notificationEventBus.alertUpdateEvent$.subscribe((data) => {
            this.addAlert(data);
        });
        // this.initIframe();

    }

    @HostListener('window:message', ['$event'])
    receiveMessage(event) {
        if (event.origin.includes(this.validationManager.resolveAPIBase())) {
            let data = JSON.parse(event.data);
            if (data.messageType === 'inspire-connect-session-data') {
                let accessData = JSON.parse(data.accessToken);
                let userData = JSON.parse(data.userData);
                if (!isNullOrUndefined(accessData)) {
                    localStorage.setItem(AppConstants.ACCESS_TOKEN, accessData.accessToken);
                    localStorage.setItem(AppConstants.REFRESH_TOKEN, accessData.refreshToken);
                    localStorage.setItem(AppConstants.EXPIRES_IN, accessData.expiresIn);
                    localStorage.setItem(AppConstants.CURRENT_USER, JSON.stringify(userData));
                }
                if (!localStorage.getItem(AppConstants.CURRENT_USER)) {
                    this.appAuthGuard.redirectToProfile();
                }
            } else {
                this.appAuthGuard.redirectToWelcome();

            }
        }
    }

    ngOnInit(): void {

        this.initIframe();
    }


    initIframe() {
        let iframe = document.createElement('iframe');
        iframe.src = this.validationManager.resolveAPIBase() + '/users/session-management/window';
        iframe.id = 'session-manager-iframe';
        this.elementRef.nativeElement.appendChild(iframe);
    }


    getUrl() {
        return this.validationManager.resolveAPIBase();
    }

    addAlert(alert: AlertModel): void {
        this.alertList.push(alert);
        setTimeout(() => {
            alert.show = true;
            setTimeout(() => {
                alert.show = false;
                setTimeout(() => {
                    this.alertList.pop();
                }, 4000);
            }, 2000);
        }, 1000);
    }

}
