import {ElementRef, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConstants} from './app.constants';
import {INSUserProfile, ScopeDataBlocks} from './models/user/ins.user.profile';
import {INSAuthResponse} from './models/responce/ins.auth.response';
import {SocialMediaLink} from './models/user/social.media.link';
import {isNullOrUndefined} from 'util';

@Injectable()
export class AppAuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router) {
    }

    canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        let userProfile: INSUserProfile = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER));

        if (!userProfile && JSON.parse(localStorage.getItem(AppConstants.REMEMBER_ME))) {
            userProfile = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER));
            this.saveCurrentUser(userProfile);
            // sessionStorage.setItem(AppConstants.ACCESS_TOKEN, localStorage.getItem(AppConstants.ACCESS_TOKEN));
            // sessionStorage.setItem(AppConstants.REFRESH_TOKEN, localStorage.getItem(AppConstants.REFRESH_TOKEN));
            // sessionStorage.setItem(AppConstants.PROVIDER, localStorage.getItem(AppConstants.PROVIDER));
            // sessionStorage.setItem(AppConstants.EMAIL, localStorage.getItem(AppConstants.EMAIL));
        }

        if (userProfile) {
            return true;
        } else {
            this.loadProfileIfCurrentUserNotFound();
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(route, state);
    }

    loadProfileIfCurrentUserNotFound() {
        let accessToken = localStorage.getItem(AppConstants.ACCESS_TOKEN);
        let refreshToken = localStorage.getItem(AppConstants.REFRESH_TOKEN);
        let userData = localStorage.getItem(AppConstants.CURRENT_USER);
        if (isNullOrUndefined(accessToken) || !isNullOrUndefined(refreshToken) || !isNullOrUndefined(userData)) {
            this.redirectToWelcome();
            return false;
        }
    }


    doIfCurrentUserFound() {
        let userProfile: INSUserProfile = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER));
        if (!userProfile && JSON.parse(localStorage.getItem(AppConstants.REMEMBER_ME))) {
            userProfile = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER));
            this.saveCurrentUser(userProfile);
        }
        if (userProfile) {
            this.redirectToProfile();
        } else {
            this.redirectToWelcome();
        }
    }

    private makeUserProfile(scopeData: ScopeDataBlocks[]) {
        let userProfile: INSUserProfile = new INSUserProfile();
        for (const data of scopeData) {
            switch (data.scopeCode) {
                case 'FULL_NAME' :
                    userProfile.fullName = data.data;
                    break;
                case 'BIRTHDAY' :
                    userProfile.birthday = data.data;
                    break;
                case 'EMAIL' :
                    userProfile.email = data.data;
                    break;

            }
        }
        return userProfile;
    }

    saveCurrentUserAuth(auth: INSAuthResponse) {
        this.saveCurrentUserAuthToLocalStorage(auth);
    }

    saveCurrentUserId(userId: string) {
        // sessionStorage.setItem(AppConstants.USER_ID, userId);
        localStorage.setItem(AppConstants.USER_ID, userId);

    }

    saveCurrentUserAuthToLocalStorage(auth: INSAuthResponse) {
        localStorage.setItem(AppConstants.ACCESS_TOKEN, auth.accessToken);
        localStorage.setItem(AppConstants.REFRESH_TOKEN, auth.refreshToken);
        localStorage.setItem(AppConstants.PROVIDER, auth.provider);
        localStorage.setItem(AppConstants.USERNAME, auth.username);
        localStorage.setItem(AppConstants.EMAIL, auth.username);
        if (auth.rememberMe) {
            localStorage.setItem(AppConstants.REMEMBER_ME, auth.rememberMe.toString());
        }
    }

    saveCurrentUserToLocalStorage(userProfile: INSUserProfile) {
        localStorage.setItem(AppConstants.CURRENT_USER, JSON.stringify(userProfile));
    }

    saveCurrentUserToSessionStorageNorRememberMe(userProfile: INSUserProfile) {
        localStorage.setItem(AppConstants.USER, JSON.stringify(userProfile));
    }

    signOut() {
        this.clearStorage();
        this.redirectToWelcome();
    }


    clearStorage() {
        sessionStorage.clear();
        localStorage.clear();
    }

    /* ******************* */
    saveCurrentUser(userProfile: INSUserProfile) {
        localStorage.setItem(AppConstants.CURRENT_USER, JSON.stringify(userProfile));
    }

    saveLinkRequest(linkrequst: Array<SocialMediaLink>) {
        localStorage.setItem(AppConstants.SOCIAL_LINK, JSON.stringify(linkrequst));
    }

    getLinkRequest() {
        return JSON.parse(localStorage.getItem(AppConstants.SOCIAL_LINK));
    }

    redirectToWelcome() {
        this.router.navigate([AppConstants.WELCOME_ROUTE]);
    }

    redirectToProfile() {
        this.router.navigate([AppConstants.HOME_ROUTE]);
    }
}
