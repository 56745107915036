import {Route} from '@angular/router';
import {AppUrlMapping} from '../../app.url.mapping';
import {PersonalDetailsComponent} from '../home/personal-details/personal-details.component';
import {HomeComponent} from './home.component';
import {AppConstants} from '../../app.constants';
import {ActivityComponent} from './activity/activity.component';
import {PrivacySettingsComponent} from './privacy-settings/privacy-settings.component';
import {Applications} from './applications/applications';
import {InterestsComponent} from './interests/interests.component';
import {AppAuthGuard} from '../../app.authguard';

export const MODULE_ROUTES_HOME: Route[] = [

    {
        path: AppUrlMapping.HOME_ROUTE,
        children: [
            {path: AppConstants.PERSONAL_DETAILS, component: PersonalDetailsComponent, canActivate: [AppAuthGuard]},
            {path: AppConstants.ACTIVITY, component: ActivityComponent, canActivate: [AppAuthGuard]},
            {path: AppConstants.PRIVACY, component: PrivacySettingsComponent, canActivate: [AppAuthGuard]},
            {path: AppConstants.APPLICATIONS, component: Applications, canActivate: [AppAuthGuard]},
            {path: AppConstants.INTERESTS, component: InterestsComponent, canActivate: [AppAuthGuard]},
            {path: '', redirectTo: AppConstants.PERSONAL_DETAILS, pathMatch: 'full'}

        ],
        component: HomeComponent, canActivate: [AppAuthGuard]
    }

];

