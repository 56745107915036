import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {InterestTag} from '../../../../models/profile/InterestTag';
import {DerivedInterestData, ManualInterestData} from '../../../../models/profile/ins.interest.det';
import {isNullOrUndefined} from 'util';
import {DomSanitizer} from '@angular/platform-browser';
import {ValidationsManager} from '../../../../utill/validations/validations.manager';

@Component({
    selector: 'app-interest-thumbnail',
    templateUrl: './interest-thumbnail.component.html',
    styleUrls: ['./interest-thumbnail.component.css']
})
export class InterestThumbnailComponent implements OnInit, OnChanges {
    @Input()
    manualInterest: ManualInterestData;

    @Input()
    interestTagList: Array<string>;
    @Input()
    derivedInterest: DerivedInterestData;
    @Input()
    isDerived: boolean;
    @Output()
    selectedDerivedInterest = new EventEmitter();
    @Output()
    selectedManualInterest = new EventEmitter();
    @Output()
    isEdit = new EventEmitter();
    @Output()
    isDelete = new EventEmitter();
    @Output()
    goToView = new EventEmitter();
    showMore: boolean;

    isFacebook: boolean;
    loadThumb: boolean;
    imageUrl: string;
    displayTag: string;
    displayParentTag: string;

    isTwitter: boolean;
    isInsta: boolean;

    displayIndex = 0;

    constructor(private validationsManager: ValidationsManager) {
    }

    ngOnInit() {
        this.showMore = false;
        this.loadThumb = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes['derivedInterest'])) {
            this.checkSocialMediaProfile();
        }
        if (!isNullOrUndefined(changes['interestTagList'])) {
            this.interestTagList = changes['interestTagList'].currentValue;
            this.getImageUrl();
        }
    }

    onClickFlowLink() {
        this.goToView.emit(true);
        if (this.isDerived) {
            this.selectedDerivedInterest.emit(this.derivedInterest);
        } else {
            this.selectedManualInterest.emit(this.manualInterest);
        }
    }

    private getImageUrl() {
        if (isNullOrUndefined(this.imageUrl)) {
            this.imageUrl = '\'../../../../../assets/img/room1.jpg\'';
        }
        this.imageUrl = this.getInterestImage();
        this.loadThumb = true;

    }

    getScore() {
        if (this.isDerived) {
            return this.derivedInterest ? this.derivedInterest.score.toFixed(2) : 5.00;
        }
        return this.manualInterest ? this.manualInterest.score.toFixed(2) : 5.00;
    }

    onMoreClicked() {
        this.showMore = !this.showMore;
    }

    getTagName(name: string) {
        if (!isNullOrUndefined(name)) {
            let words = name.split(/(?=[A-Z])/);
            let interestName = '';
            for (let word of words) {
                interestName = interestName + word + ' ';
            }
            return interestName;
        }
    }

    getInterestImage() {
        if (this.isDerived) {
            return this.validationsManager.resolveAPIBase()+'/resources/external-assets/images/' + this.derivedInterest.interestCode.toLowerCase() ;
        }
        return this.validationsManager.resolveAPIBase()+'/resources/external-assets/images/' +this.manualInterest.interestCode.toLowerCase();
    }

    checkSocialMediaProfile() {
        for (const timeBucket of this.derivedInterest.interestTimeBuckets) {
            if (timeBucket.origin === 'FACEBOOK') {
                this.isFacebook = true;
            }
            if (timeBucket.origin === 'TWITTER') {
                this.isTwitter = true;
            }
            if (timeBucket.origin === 'INSTAGRAM') {
                this.isInsta = true;
            }
        }
    }

    onEditInterest() {
        this.onClickFlowLink();
        this.isEdit.emit(true);
    }

    deleteInterest() {
        console.log('haaa');
        this.onClickFlowLink();
        this.isDelete.emit(true);
    }
}

