import { Route } from '@angular/router';
import { WelcomeComponent } from './modules/welcome/welcome.component';
// import { AdminComponent } from './pages/admin/admin.component';
// import { HomeComponent } from './pages/home/home.component';
import { AppConstants } from './app.constants';
import {AppAuthGuard} from './app.authguard';
import { ProfileComponent } from './modules/profile/profile.component';
import {HomeComponent} from './modules/home/home.component';

export const MODULE_ROUTES_APP: Route[] = [

    {
        path: AppConstants.WELCOME_ROUTE, component: WelcomeComponent

    }
    ,
    {
        path: AppConstants.HOME_ROUTE, component: HomeComponent, canActivate: [AppAuthGuard]
    }
    ,
    {
        path: '', redirectTo: AppConstants.HOME_ROUTE, pathMatch: 'full', canActivate: [AppAuthGuard]
    }
    ,
    {
        path: '**', redirectTo: AppConstants.HOME_ROUTE, pathMatch: 'full', canActivate: [AppAuthGuard]
    }

]

export const MODULE_COMPONENTS = [
    WelcomeComponent,
    ProfileComponent
    // , AdminComponent
    // , HomeComponent
]