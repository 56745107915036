import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DerivedInterestData} from '../../../../models/profile/ins.interest.det';
import {DatePipe} from '@angular/common';
import {LoaderService} from '../../../../services/loader.service';
import * as moment from 'moment';

@Component({
    selector: 'app-interest-line-chart',
    templateUrl: './interest-stats.component.html',
    styleUrls: ['./interest-stats.component.css']
})
export class InterestLineChartComponent implements OnInit {

    @Input()
    derivedInterest: DerivedInterestData;

    @Output()
    goBack: EventEmitter<boolean> = new EventEmitter<boolean>();

    multi = [];

    initGraph: boolean = false;

    columnNames = ['Week ', 'Facebook', 'Instagram'];
    // line, area
    autoScale = true;

    myOptions = {
        is3D: true
    };

    constructor(private loaderService: LoaderService) {
    }

    ngOnInit() {
        this.loaderService.showLoader();
        this.setDataWithMissingPeriods();
    }


    changeMenu() {
        document.getElementsByClassName('content')[0].classList.toggle('active-side-menu');
    }

    setDataWithMissingPeriods() {
        const datePipe = new DatePipe('en-US');

        console.log(this.derivedInterest, 'derived interest');
        let fromDate = this.derivedInterest.interestTimeBuckets[0].fromDate;
        let toDate = this.derivedInterest.interestTimeBuckets[this.derivedInterest.interestTimeBuckets.length - 1].toDate;
        let currentDate = moment(fromDate);
        let stop = false;

        while (!stop) {
            let temp = [];
            const myFormattedFromDate = datePipe.transform(currentDate.toDate(), 'shortDate');
            let tempToDate = moment(currentDate).add(7, 'day');
            const myFormattedToDate = datePipe.transform(tempToDate.toDate(), 'shortDate');

            let bucket = this.getMatchingBucket(currentDate, tempToDate);
            let index = this.checkCreatedMatchSlot('');
            let formattedDate = myFormattedFromDate + ' - ' + myFormattedToDate;
            if (index > -1) {
                temp = this.multi[index];
            }
            if (bucket === null) {
                temp.push(formattedDate, 0, 0);
            } else {
                temp[0] = formattedDate;

                if (bucket.origin.toLowerCase() === 'facebook') {
                    temp[1] = bucket.score;
                    temp[2] = 0;
                } else if (bucket.origin.toLowerCase() === 'instagram') {
                    temp[2] = bucket.score;
                    temp[1] = 0;

                }else{
                //    other social media goes here
                }

                // temp.push(formattedDate, bucket.score, bucket.score);
            }

            if (moment(tempToDate).isAfter(toDate, 'day')) {
                stop = true;
            }
            this.multi.push(temp);
            currentDate = moment(tempToDate).add(1, 'day');

        }

        console.log(this.multi, 'multi meee');
    }

    checkCreatedMatchSlot(slot: any) {
        for (let i = 0; i < this.multi.length; i++) {
            if (this.multi[i][0] === slot) {
                return i;
            }
            console.log(this.multi[i]) , 'ding dong';
        }
        return -1;
    }


    getMatchingBucket(startDate: any, endDate: any) {
        for (const data of this.derivedInterest.interestTimeBuckets) {
            if (moment(data.fromDate).isSame(startDate, 'day')
                || (moment(data.fromDate).isAfter(startDate, 'day') && moment(data.fromDate).isBefore(endDate, 'day'))
                || (moment(data.toDate).isAfter(startDate, 'day') && moment(data.toDate).isBefore(endDate, 'day'))
            ) {
                return data;
            }
        }
        return null;
    }

    onChartReady() {
        this.loaderService.hideLoader();
    }

    onCancel() {
        this.goBack.emit(true);
    }

}
