import {Component, ElementRef, OnInit} from '@angular/core';
import {NavBarItemModel} from '../../shared/nav-bar/nav-bar-item.model';
import {AppConstants} from '../../app.constants';
import {AppAuthGuard} from '../../app.authguard';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    navBarItems: NavBarItemModel[] = [];

    constructor(private authGuard: AppAuthGuard, private elementRef: ElementRef) {
        this.initjs();
        this.navBarItems.push(new NavBarItemModel('Account info', AppConstants.PERSONAL_DETAILS, '../../assets/img/account-info.svg', 'general'));
        this.navBarItems.push(new NavBarItemModel('Interests', AppConstants.INTERESTS, '../../assets/img/interests.svg', 'general'));
        // this.navBarItems.push(new NavBarItemModel('Activity', AppConstants.ACTIVITY, '../../assets/img/activity.svg' ,'general'));
        // this.navBarItems.push(new NavBarItemModel('Privacy settings', AppConstants.PRIVACY, '../../assets/img/privacy.svg','general'));
        this.navBarItems.push(new NavBarItemModel('Applications', AppConstants.APPLICATIONS, '../../assets/img/applications.svg', 'dev'));
    }

    ngOnInit() {
    }

    initjs() {
        let htmlScriptElement = document.createElement('script');
        htmlScriptElement.src = '../../assets/js/session-logout.js';
        this.elementRef.nativeElement.appendChild(htmlScriptElement);
    }


    onSignOut() {
         this.authGuard.signOut();
    }

}
