import { Injectable, EventEmitter } from '@angular/core';
import { AuthCredentials } from '../../models/authdata/auth.credentials';
import { SocialSigninCredentials } from '../../models/authdata/social.signin.credentials';

@Injectable()
export class WelcomeEventBus {

    public signinCredsAdded$: EventEmitter<AuthCredentials>;
    public socialSigninCredsAdded$: EventEmitter<SocialSigninCredentials>;
    public signupUserAdded$: EventEmitter<AuthCredentials>;
    public emailAdded$: EventEmitter<string>;
    public signupMessageAdded$: EventEmitter<string>;
    public forgotPasswordSent$: EventEmitter<string>;
    public resendConfirmation$: EventEmitter<string>;


    constructor() {
        this.signinCredsAdded$ = new EventEmitter<AuthCredentials>();
        this.socialSigninCredsAdded$ = new EventEmitter<SocialSigninCredentials>();
        this.signupUserAdded$ = new EventEmitter<AuthCredentials>();
        this.signupMessageAdded$ = new EventEmitter<string>();
        this.emailAdded$ = new EventEmitter<string>();
        this.forgotPasswordSent$ = new EventEmitter<string>();
        this.resendConfirmation$ = new EventEmitter<string>();
    }

    public addSigninCredentials(signinCredentials: AuthCredentials): void {
        this.signinCredsAdded$.emit(signinCredentials);
    }

    public addSocialSigninCredentials(socialSigninCredentials: SocialSigninCredentials) {
        this.socialSigninCredsAdded$.emit(socialSigninCredentials);
    }

    public addSignupUser(signupUser: AuthCredentials) {
        this.signupUserAdded$.emit(signupUser);
    }

    public addAddEmail(email: string) {
        this.emailAdded$.emit(email);
    }

    public addSignUpMessage(signinError: string) {
        this.signupMessageAdded$.emit(signinError);
    }

    public sendForgotPassword(fogotPasswordMsg: string) {
        this.signupMessageAdded$.emit(fogotPasswordMsg);
    }

    public resendConfirmation(confirmationMsg: string) {
        this.signupMessageAdded$.emit(confirmationMsg);
    }

}