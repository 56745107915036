import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {INSAuthResponse} from '../models/responce/ins.auth.response';
import {AppConstants} from '../app.constants';
import {INSResponse} from '../models/responce/ins.response';
import {INSException} from '../models/ins.exception';
import {isNullOrUndefined} from 'util';
import {AppAuthGuard} from '../app.authguard';


export abstract class BaseService {

    protected accessToken: string;
    httpResponse: any;
    private API_BASE_URL = '';

    protected alive = true;

    constructor(protected http: HttpClient,
                protected authGuard: AppAuthGuard) {
        this.API_BASE_URL = this.resolveAPIBase();

    }

    protected resolveAPIBase(): string {

        let apiBase: string;

        if (environment.API_HOST_IS_SAME) {

            let hostname = window.location.hostname;

            if (hostname.startsWith(environment.DEV_IDENTIFIER_PREFIX) && hostname.endsWith(environment.DEV_IDENTIFIER_SUFFIX)) {
                apiBase = environment.DEV_PROTOCOL + environment.DEV_API_PREFIX + hostname + environment.DEV_API_SUFFIX;
            } else if (hostname.startsWith(environment.QA_IDENTIFIER_PREFIX) && hostname.endsWith(environment.QA_IDENTIFIER_SUFFIX)) {
                apiBase = environment.QA_PROTOCOL + environment.QA_API_PREFIX + hostname + environment.QA_API_SUFFIX;
            } else if (hostname.startsWith(environment.STAGING_IDENTIFIER_PREFIX) && hostname.endsWith(environment.STAGING_IDENTIFIER_SUFFIX)) {
                apiBase = environment.STAGING_PROTOCOL + environment.STAGING_API_PREFIX + hostname + environment.STAGING_API_SUFFIX;
            } else if (hostname.startsWith(environment.PROD_IDENTIFIER_PREFIX) && hostname.endsWith(environment.PROD_IDENTIFIER_SUFFIX)) {
                apiBase = environment.PROD_PROTOCOL + environment.PROD_API_PREFIX + hostname + environment.PROD_API_SUFFIX;
            }

        } else {
            apiBase = environment.EXT_API_HOST;
        }

        return apiBase;
    }

    protected get<T>(apiUrl: string, params: HttpParams) {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const options = {headers, params};

        return this.http.get<T>(this.API_BASE_URL + '/' + apiUrl, options).pipe(
            map(d => {
                return d;
            }),
            e => {
                return e;
            }
        );
    }

    protected getFromExternalAPI<T>(apiUrl: string, params: HttpParams) {
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            });
        const options = {headers: headers, search: params};

        return this.http.get<T>(apiUrl, options).pipe(
            map(d => {
                return d;
            }),
            e => {
                return e;
            }
        );
    }

    protected getWithAuth<T>(apiUrl: string, params: HttpParams, headers?: HttpHeaders): Observable<any> {
        const accessToken = this.getAccessTokenFromSession();
        const options = {
            headers: new HttpHeaders(
                {'Content-Type': 'application/json'}
            ),
            params: params.set('access-token', accessToken),
            withCredentials: true
        };
        console.log('get with auth');

        return this.http.get<T>(this.API_BASE_URL + '/' + apiUrl, options).pipe(
            map(d => {
                return d;
            }),
            catchError(err => {
                if (this.isAccessTokenExpired(err)) {
                    // this.authGuard.redirectToWelcome();
                    return this.refreshAuthLocal().pipe(map(
                        res => {
                            this.saveAccessTokenToSession(res);
                            console.log('new access token arrived');
                            return this.getWithAuth(apiUrl, params);
                        }
                    ));
                }
                return this.handleError(err);
            })
        );

    }

    protected post<T>(apiUrl: string, body: any) {
        const httpPostOptions = {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
            withCredentials: true,
        };
        return this.http.post<T>(this.API_BASE_URL + '/' + apiUrl, JSON.stringify(body), httpPostOptions).pipe(
            map(d => {
                return d;
            }),
            error => {
                return error;
            }
        );
    }

    protected postWithAuth<T>(apiUrl: string, body: any, params: HttpParams) {
        const accessToken = this.getAccessTokenFromSession();
        const httpPostOptions = {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
            params: params.set('access-token', accessToken),
            withCredentials: true,
        };
        return this.http.post<T>(this.API_BASE_URL + '/' + apiUrl, JSON.stringify(body), httpPostOptions).pipe(
            map(d => {
                return d;
            }),
            catchError(err => {
                    if (this.isAccessTokenExpired(err)) {
                        // this.authGuard.redirectToWelcome();
                        return this.refreshAuthLocal().pipe(map(
                            res => {
                                this.saveAccessTokenToSession(res);
                                return this.postWithAuth(apiUrl, body, params);
                            }
                        ));
                    }
                    return this.handleError(err);
                }
            ));
    }

    uploadWithAuth<T>(apiUrl: string, postData: any, file: File, params: HttpParams) {
        const accessToken = this.getAccessTokenFromSession();

        let formData: FormData = new FormData();

        formData.append('file', file, file.name);

        const httpPostOptions = {
            headers: new HttpHeaders(
                {
                    'Accept': 'application/json'
                }),
            params: params.set('access-token', accessToken),
            withCredentials: true,
        };
        return this.http.post<T>(this.API_BASE_URL + '/' + apiUrl, formData, httpPostOptions).pipe(
            map(d => {
                return d;
            }),
            catchError(err => {
                if (this.isAccessTokenExpired(err)) {
                    // this.authGuard.redirectToWelcome();
                    return this.refreshAuthLocal().pipe(map(
                        res => {
                            this.saveAccessTokenToSession(res);
                            return this.uploadWithAuth(apiUrl, postData, file, params);
                        }
                    ));
                }
                return this.handleError(err);
            })
        );
    }

    protected postWithoutAuth<T>(apiUrl: string, body: any, params: HttpParams) {
        const httpPostOptions = {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
            params: params,
            withCredentials: true,
        };
        return this.http.post<T>(this.API_BASE_URL + '/' + apiUrl, JSON.stringify(body), httpPostOptions).pipe(
            map(d => {
                return d;
            }),
            err => {
                return err;
            });
    }


    protected putWithAuth<T>(apiUrl: string, body: any, params: HttpParams) {
        const accessToken = this.getAccessTokenFromSession();
        params = params.set('access-token', accessToken);
        const options = {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
            params: params,
            withCredentials: true,
        };
        return this.http.put<T>(this.API_BASE_URL + '/' + apiUrl, JSON.stringify(body), options).pipe(
            map(d => {
                return d;
            }), catchError(err => {
                if (this.isAccessTokenExpired(err)) {
                    // this.authGuard.redirectToWelcome();
                    return this.refreshAuthLocal().pipe(map(
                        res => {
                            this.saveAccessTokenToSession(res);
                            return this.putWithAuth(apiUrl, body, params);
                        }
                    ));
                }
                return this.handleError(err);

            }));
    }

    protected deleteWithAuth<T>(apiUrl: string, params: HttpParams) {
        const accessToken = this.getAccessTokenFromSession();
        const options = {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
            params: params.set('access-token', accessToken),
            withCredentials: true,
        };
        return this.http.delete<T>(this.API_BASE_URL + '/' + apiUrl, options).pipe(
            map(d => {
                return d;
            }), catchError(err => {
                if (this.isAccessTokenExpired(err)) {
                    return this.refreshAuthLocal().pipe(map(
                        res => {
                            this.saveAccessTokenToSession(res);
                            return this.deleteWithAuth(apiUrl, params);
                        }
                    ));
                }
                return this.handleError(err);

            }));
    }


    /* ******************************** */
    protected extractData(res: INSResponse<any>) {
        this.httpResponse = res;
        if (res) {
            const body = res.results;
            return body;
        } else {
            return {};
        }
    }

    private handleError(error: any): Observable<never>{
        this.authGuard.redirectToWelcome();
        this.authGuard.clearStorage();
        return throwError(error);
    }

    public refreshAuthLocal(): Observable<INSAuthResponse> {
        const params: HttpParams = new HttpParams();
        const apiUrl = 'auth/token/refresh?refresh-token=' + localStorage.getItem(AppConstants.REFRESH_TOKEN) + '&access-token=' + localStorage.getItem(AppConstants.ACCESS_TOKEN);
        return this.get(apiUrl, params);
    }

    protected saveAccessTokenToSession(accessPacket: any): void {
        localStorage.removeItem(AppConstants.ACCESS_TOKEN);
        localStorage.removeItem(AppConstants.REFRESH_TOKEN);
        localStorage.setItem(AppConstants.ACCESS_TOKEN, accessPacket.accessToken);
        localStorage.setItem(AppConstants.REFRESH_TOKEN, accessPacket.refreshToken);
    }

    protected isAccessTokenExpired(err: any) {
        const message: string = err.error.results[0].message || '';
        return (message.startsWith('invalid token'));
    }

    protected getAccessTokenFromSession(): string {
        // return sessionStorage.getItem(AppConstants.ACCESS_TOKEN);
        return localStorage.getItem(AppConstants.ACCESS_TOKEN);
    }


    protected makeAck(res: INSResponse<any>) {
        if (!isNullOrUndefined(res.results)) {

            return res.results[0];
        }
    }

    protected makeError(error: any) {
        return new INSException(error.error.results[0].message);
    }

    protected makeRes(res: INSResponse<any>) {
        return res.results;
    }


}
