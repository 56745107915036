import {LoginProviderSettings} from '../../modules/home/applications/login-provider-settings/LoginProviderSettings';

export class INSUsersApp {
    appName: string;
    appKey: string;
    appImage: string;
    appSecret: string;
    createdTime: string;
    appOwner: string;
    disabled: boolean;
    description: string;
    domains: string[];
    requiredConsent: Array<RequiredConsent>;
    allowedConsent: Array<string>;
    allowedLoginOptions: Array<LoginProviderSettings>;
    allowedLdapLoginOptions: Array<LoginProviderSettings>;


    constructor() {
        this.description = '';
        this.appName = '';
        this.appImage = '';
        this.domains = [];
        this.allowedLoginOptions = [];
        this.allowedLdapLoginOptions = [];
        this.requiredConsent = [new RequiredConsent('DEFAULT', true)];
    }
}

export class RequiredConsent {
    consent: string;
    displayName: string;
    required: boolean;
    selected: boolean = false;


    constructor(consent: string, required: boolean) {
        this.consent = consent;
        this.required = required;
    }

}
