export class INSAuthResponse {
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
    rememberMe: boolean;
    username: string;
    provider: string;

    message: any;


    constructor() {
    }
}


