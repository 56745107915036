import {Component, OnInit, ViewChild, ElementRef, NgZone, HostListener} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {AuthCredentials} from '../../../models/authdata/auth.credentials';
import {WelcomeEventBus} from '../welcome.event.bus';
import {InternalAuthService} from '../../../services/auth.service';
import {ValidationsManager} from '../../../utill/validations/validations.manager';
import {ISubscription} from 'rxjs/Subscription';
import {NotificationEventBus} from '../../../notification.event.bus';
import {AlertModel} from '../../../models/internal/alert.model';
import {Router} from '@angular/router';
import {AppConstants} from '../../../app.constants';
import {INSException} from '../../../models/ins.exception';
import {INSMessage} from '../../../models/ins.message';
import {INSAuthResponse} from '../../../models/responce/ins.auth.response';
import {INSUserProfile, ScopeDataBlocks} from '../../../models/user/ins.user.profile';
import {UserManagementService} from '../../../services/user.management.service';
import {AppAuthGuard} from '../../../app.authguard';
import {isNullOrUndefined} from 'util';
import {ProfileService} from '../../../services/profile.service';
import {LoaderService} from '../../../services/loader.service';
import {InsUser} from '../../../models/user/ins.user';


@Component({
    selector: 'app-sigin-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

    signinForm: FormGroup;
    passwordResetForm: FormGroup;
    subscription: ISubscription;
    nextClicked: boolean = false;
    nextBtnTxt: string = 'Next';
    timeout = false;
    tempAccessToken: string;
    facebookUserData: any;
    twitter_window;
    userEmail: string;
    topWording: string;
    description: string;
    displayMode = 0; // 0=>username ; 1=>password; 2=> verification code ; 3=> resend email; 4=>change email
    status = '';
    user = new AuthCredentials();
    formErrors = {
        'username': '',
        'password': '',
        'verificationCode': '',
        'forgotPasswordEmail': '',
        'changeEmail': ''
    };
    formErrorsEmail = {
        'username': ''
    };
    validationMessages = {
        'username': {
            'required': 'Username required.',
            'pattern': 'Invalid username.',
        },
        'password': {
            'required': 'Password required.',
            'invalid': 'Invalid password.',
        },
        'verificationCode': {
            'required': 'Verification code required.',
        },
        'changeEmail': {
            'required': 'Email required.',
            'pattern': 'Invalid email'
        },
        'forgotPasswordEmail': {
            'required': 'Email required.',
            'pattern': 'Invalid email'
        }
    };

    auth: INSAuthResponse = new INSAuthResponse();

    @ViewChild('username') emailFocus: ElementRef;
    @ViewChild('password') passwordFocus: ElementRef;
    @ViewChild('verificationCode') verificationCodeFocus: ElementRef;
    @ViewChild('forgotPasswordEmail') passwordForgetEmailFocus: ElementRef;
    @ViewChild('changeEmail') changeEmailFocus: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private welcomeEventBus: WelcomeEventBus,
                private router: Router,
                private profileService: ProfileService,
                private authService: InternalAuthService,
                private authGuard: AppAuthGuard,
                private validationsManager: ValidationsManager,
                private userManagementService: UserManagementService,
                private _ngZone: NgZone,
                private notificationEventBus: NotificationEventBus,
                private loaderService: LoaderService) {

    }

    ngOnInit() {
        this.makeSignInForm();
        this.makePasswordResetForm();
        sessionStorage.setItem(AppConstants.PROVIDER, 'INSPIRE_CONNECT');
        this.topWording = 'Login';
    }

    makeSignInForm() {
        this.signinForm = this.formBuilder.group(
            {
                username: ['', Validators.required],
                verificationCode: ['', Validators.required],
                resendEmail: ['', Validators.required],
                changeEmail: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
                forgotPasswordEmail: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
                password: ['', Validators.compose([<any>Validators.required])],
            }
        );

        this.subscription = this.welcomeEventBus.signupMessageAdded$.subscribe(error => this.onSignInFailed(this.getErrorMsg(error)));
        setTimeout(() => this.emailFocus.nativeElement.focus(), 300);
    }

    onValueChanged(formName: any, formErrors: any, data?: any) {
        if (!formName) {
            return;
        }
        const form = formName;
        for (const field in formErrors) {
            // clear previous error message (if any)
            formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {

                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    formErrors[field] += messages[key] + ' ';
                }
            }
        }
        return formErrors;
    }

    makePasswordResetForm() {
        this.passwordResetForm = this.formBuilder.group(
            {
                username: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
            }
        );
        this.passwordResetForm.valueChanges
            .subscribe(data => this.onValueChanged(this.passwordResetForm, this.formErrorsEmail, data));
        this.formErrorsEmail = this.onValueChanged(this.passwordResetForm, this.formErrorsEmail);
    }

    onSignIn(signInCredentials: AuthCredentials, valid: boolean) {
        this.user = signInCredentials;
        this.user.accessToken = '';
        this.user.idToken = '';
        this.user.source = 'INSPIRE_CONNECT';
        if (this.signinForm.controls.username.valid && this.signinForm.controls.password.valid) {
            this.setFinalValidationForForm(this.user);
        } else {
            this.showInvalidFields();
        }
    }

    onVerifyCode(signInCredentials: AuthCredentials, valid: boolean) {
        if (valid) {
            this.setFinalValidationForCode(this.user);
        } else {
            this.showInvalidFields();
        }
    }


    setFinalValidationForForm(signInCredentials: AuthCredentials) {
        if (this.signinForm.controls.username.status === 'INVALID') {
            this.emailFocus.nativeElement.focus();
        } else if (this.signinForm.controls.password.status === 'INVALID') {
            this.passwordFocus.nativeElement.focus();
        } else {
            this.doOnSignIn(signInCredentials);
        }
    }

    setFinalValidationForCode(signInCredentials: AuthCredentials) {
        if (this.formErrors.verificationCode !== '') {
            this.verificationCodeFocus.nativeElement.focus();
        } else {
            this.doOnVerifyEmail(signInCredentials);
        }
    }

    onSignInFailed(errMessage: string) {
        this.notificationEventBus.updateAlertContent(new AlertModel('error', errMessage));
        this.signinForm.controls.password.setValue('');
    }

    showInvalidFields() {
        if (this.signinForm.controls.username.status === 'INVALID') {
            this.emailFocus.nativeElement.focus();
            document.getElementById('email').classList.add('input-text--invalid');
            this.formErrors.username = this.validationMessages.username.required;
        } else {
            if (this.displayMode === 0) {
                try {
                    document.getElementById('email').classList.remove('input-text--invalid');
                } finally {
                    this.formErrors.username = '';
                }
            }
        }
        if (this.displayMode === 1 && this.signinForm.controls.password.status === 'INVALID' && (this.signinForm.controls.password.value !== '' || this.signinForm.controls.password.touched)) {
            this.passwordFocus.nativeElement.focus();
            document.getElementById('pass').classList.add('input-text--invalid');
            this.formErrors.password = this.validationMessages.password.invalid;
        } else {
            if (this.displayMode === 1) {
                try {
                    document.getElementById('pass').classList.remove('input-text--invalid');
                } finally {
                    this.formErrors.password = '';
                }
            }
        }
        if (this.displayMode === 2 && this.signinForm.controls.verificationCode.status === 'INVALID' && (this.signinForm.controls.verificationCode.value !== '' || this.signinForm.controls.verificationCode.touched)) {
            this.passwordFocus.nativeElement.focus();
            document.getElementById('code').classList.add('input-text--invalid');
            this.formErrors.verificationCode = this.validationMessages.verificationCode.required;
        } else {
            if (this.displayMode === 2) {
                try {
                    document.getElementById('code').classList.remove('input-text--invalid');
                } finally {
                    this.formErrors.verificationCode = '';
                }
            }
        }
        if (this.displayMode === 5 && this.signinForm.controls.forgotPasswordEmail.status === 'INVALID') {
            this.passwordForgetEmailFocus.nativeElement.focus();
            if (this.signinForm.controls.forgotPasswordEmail.value.trim() === '') {
                this.formErrors.forgotPasswordEmail = this.validationMessages.forgotPasswordEmail.required;
            } else {
                this.formErrors.forgotPasswordEmail = this.validationMessages.forgotPasswordEmail.pattern;
            }
        } else {
            if (this.displayMode === 5) {
                try {
                    document.getElementById('forgotPassword').classList.remove('input-text--invalid');
                } finally {
                    this.formErrors.forgotPasswordEmail = '';
                }
            }
        }
        if (this.signinForm.controls.changeEmail.status === 'INVALID') {
            this.emailFocus.nativeElement.focus();
            document.getElementById('changeEmail').classList.add('input-text--invalid');
            if (this.signinForm.controls.changeEmail.value.trim() === '') {
                this.formErrors.changeEmail = this.validationMessages.changeEmail.required;
            } else {
                this.formErrors.changeEmail = this.validationMessages.changeEmail.pattern;
            }
        } else {
            if (this.displayMode === 4) {
                try {
                    document.getElementById('changeEmail').classList.remove('input-text--invalid');
                } finally {
                    this.formErrors.changeEmail = '';
                }
            }
        }
    }

    isInvalid(formGroup: FormGroup, formControl?: string): boolean {
        if (formControl === undefined || formControl == null) {
            return !(formGroup.status === 'VALID');
        } else {
            return formGroup.get(formControl).status === 'INVALID' && !formGroup.get(formControl).untouched;
        }
    }

    doOnSignIn(signInCredentials: AuthCredentials) {
        console.log(signInCredentials);
        this.authService.getAuth(
            (auth: INSAuthResponse) => {
                this.onSignInSuccess(auth);

            },
            (insException: any) => {

                let msg = this.getErrorMsg(insException.message);
                if (msg.toLowerCase().includes('bad credentials')) {
                    this.formErrors.password = this.validationMessages.password.invalid;
                    this.welcomeEventBus.addSignUpMessage(insException.message);
                } else if (insException.message.error.results[0].idType === 'EMAIL_VERIFICATION') {
                    let ack = insException.message.error.results[0];
                    this.userEmail = ack.id;
                    this.tempAccessToken = ack.accessToken.accessToken;
                    this.signinForm.controls.resendEmail.setValue(this.userEmail);
                    this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Verification Email has been sent to ' + this.userEmail));
                    this.displayMode = 2;
                } else {
                    this.welcomeEventBus.addSignUpMessage(insException.message);
                }
            },
            signInCredentials);
    }

    doOnVerifyEmail(signInCredentials: AuthCredentials) {
        this.userManagementService.verifyEmail(
            (auth: INSMessage) => {
                if (sessionStorage.getItem(AppConstants.PROVIDER) !== 'TWITTER') {
                    this.doOnSignIn(this.user);
                } else {
                    this._ngZone.run(() => {
                        this.router.navigate([AppConstants.HOME_ROUTE]);
                    });
                    this.onSignInSuccess(this.auth);
                }
            },
            (insException: any) => {
                if (insException.message.includes('email verification request not found')) {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', 'Invalid verification code.'));
                    this.signinForm.controls.verificationCode.setValue('');
                    this.verificationCodeFocus.nativeElement.focus();
                }
            },
            this.userEmail, 'inspire-connect', this.signinForm.controls.verificationCode.value);
    }

    loadUser() {
        this.userManagementService.loadUser(
            (user: InsUser) => {
                this.authGuard.saveCurrentUserId(user.userId);
                this.authGuard.redirectToProfile();

            }, (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            }
        );
    }

    onSignInSuccess(auth: INSAuthResponse) {
        if (!isNullOrUndefined(auth.message)) {
            if (auth.message.idType === 'EMAIL_VERIFICATION') {
                this.userEmail = auth.message.id;
                this.description = 'We sent the verification code to you. Check your emails and we are ready to roll.';
                this.tempAccessToken = auth.accessToken;
                this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Verification Email has been sent to ' + this.userEmail));
                this.loaderService.hideLoader();
                this.signinForm.controls.resendEmail.setValue(this.userEmail);
                this.displayMode = 2;
            } else if (auth.message.idType === 'MISSING_EMAIL') {
                this.tempAccessToken = auth.accessToken;
                this.displayMode = 4;
                this.status = 'MISSING_EMAIL';
                this.description = 'Hi ' + this.signinForm.controls.username.value +
                    ' ! Looks like we don\'t have your email . \n Fill in the email here, we will send you the verification link.';
                this.nextBtnTxt = 'Save';
                this.loaderService.hideLoader();
            }
        } else {
            this.saveAuthAndProceed(auth);
        }

    }

    saveAuthAndProceed(auth) {
        this.authGuard.saveCurrentUserAuth(auth);
        if (auth.rememberMe) {
            this.authGuard.saveCurrentUserAuthToLocalStorage(auth);
        }
        this.userManagementService.loadUserProfile(
            (userProfile: ScopeDataBlocks[]) => {
                this.authGuard.saveCurrentUser(this.makeUserProfile(userProfile));
                if (auth.rememberMe) {
                    this.authGuard.saveCurrentUserToLocalStorage(this.makeUserProfile(userProfile));
                }

                this.loadUser();

            },
            (insException: INSException) => {
                this.welcomeEventBus.addSignUpMessage(insException.message);
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                this.authGuard.redirectToWelcome();
            });
    }

    makeUserProfile(scopeData: ScopeDataBlocks[]) {
        let userProfile: INSUserProfile = new INSUserProfile();
        for (const data of scopeData) {
            switch (data.scopeCode) {
                case 'FULL_NAME' :
                    userProfile.fullName = data.data;
                    break;
                case 'BIRTHDAY' :
                    userProfile.birthday = data.data;
                    break;
                case 'EMAIL' :
                    userProfile.email = data.data;
                    break;

            }
        }
        return userProfile;
    }

    getNextBtnTxt(): string {
        return this.nextBtnTxt;
    }

    onNextClicked() {
        this.description = '';
        if (sessionStorage.getItem(AppConstants.PROVIDER) === 'INSPIRE_CONNECT') {
            if (this.signinForm.controls.username.value === '') {
                this.formErrors.username = this.validationMessages.username.required;
                return;
            }
            if (this.signinForm.controls.username.status === 'INVALID') {
                this.formErrors.username = this.validationMessages.username.pattern;
                return;
            }
        }
        this.loaderService.showLoader();

        if (this.displayMode === 0) {
            // this.description = 'It\'s okay ' + this.signinForm.controls.username.value + '. Give us your email, we will send you a reset link.';
            if (this.signinForm.controls.username.status !== 'INVALID') {
                this.verifyEmail(this.signinForm.controls.username.value);
            }
            this.loaderService.hideLoader();
        } else if (this.displayMode === 1) {

            this.loaderService.hideLoader();
            if (this.signinForm.controls.password.value === '') {
                this.formErrors.password = this.validationMessages.password.required;
                return;
            }
            if (this.signinForm.controls.password.status === 'INVALID') {
                this.formErrors.password = this.validationMessages.password.invalid;
                return;
            }
            this.onSignIn(this.signinForm.value, true);
        } else if (this.displayMode === 2) {
            this.description = 'We sent you an email with the link.';
            this.onVerifyCode(this.signinForm.value, this.signinForm.controls.verificationCode.valid);
            this.loaderService.hideLoader();
        } else if (this.displayMode === 3) {
            this.description = 'We sent the verification to this email. Didn\'t get it? Click below to resend it. ';
            this.onResendEmailVerification();
            this.loaderService.hideLoader();
        } else if (this.displayMode === 4) {
            if (this.status !== 'MISSING_EMAIL') {
                this.description = 'Didn\'t get the email? Changed your email? Don\'t worry. Give us your new email , we will send you the verification.';
            }else{
                this.description = 'Hi ' + this.signinForm.controls.username.value +
                    ' ! Looks like we don\'t have your email . \n Fill in the email here, we will send you the verification link.';            }
            this.userEmail = this.signinForm.controls.changeEmail.value;
            this.signinForm.controls.resendEmail.setValue(this.userEmail);
            this.loaderService.hideLoader();

            if (this.signinForm.controls.changeEmail.valid) {
                this.verifyWithEmailWithAccessToken(this.signinForm.controls.changeEmail.value);
                this.loaderService.hideLoader();

            } else {
                if (this.signinForm.controls.changeEmail.value == null || this.signinForm.controls.changeEmail.value.trim() === '') {
                    this.formErrors.changeEmail = this.validationMessages.changeEmail.required;
                } else {
                    this.formErrors.changeEmail = this.validationMessages.changeEmail.pattern;
                }
            }

        } else if (this.displayMode === 5) {
            this.description = 'It\'s okay ' + this.signinForm.controls.username.value + '. Give us your email, we will send you the reset link.';
            this.userEmail = this.signinForm.controls.forgotPasswordEmail.value;
            let username = this.signinForm.controls.username.value;
            this.loaderService.hideLoader();

            this.showInvalidFields();
            if (this.signinForm.controls.forgotPasswordEmail.valid) {
                this.sendForgetPassword(this.userEmail, username);
            }

        }
    }

    onResendEmailVerification() {
        this.verifyWithEmailWithAccessToken(this.userEmail);
    }


    onSendVerificationEmail() {
        this.displayMode = 3;
        this.nextBtnTxt = 'Send';
        this.topWording = 'Send Verification';
        this.description = 'We sent the verification to this email. Didn\'t get it? Click below to resend it. ';

    }

    onChangeEmail() {
        this.displayMode = 4;
        this.nextBtnTxt = 'Send';
        this.topWording = 'Change Email';
        this.description = ' Changed your email? Give us your new email , we will send you the verification.';

    }

    onGoBack() {
        this.displayMode = 0;
        this.description = '';
        this.nextBtnTxt = 'Next';
        this.user = new AuthCredentials();
        this.signinForm.reset();
        this.topWording = 'Login';

    }


    verifyWithEmailWithAccessToken(email: string) {

        this.userManagementService.saveEmailAndSendVerificationLink(
            (ack: INSMessage) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Check your mails and confirm your account.'));
                this.displayMode = 2;
            },
            (insException: INSException) => {
                this.welcomeEventBus.addSignUpMessage(insException.message);
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
            email, this.tempAccessToken);
    }

    sendForgetPassword(email: string, username: string) {

        this.userManagementService.sendForgetPassword(
            (ack: INSMessage) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Check your mails , we sent a reset link.'));
                this.displayMode = 0;
            },
            (insException: INSException) => {
                this.welcomeEventBus.addSignUpMessage(insException.message);
                // this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                this.notificationEventBus.updateAlertContent(new AlertModel('success', 'Check your mails , we sent a reset link.'));

            },
            email, username);
    }

    verifyEmail(email: string) {
        this.authService.checkUsernameExistence(
            (ack: any) => {
                if (ack.id.trim() == 'TAKEN') {
                    this.nextClicked = true;
                    this.displayMode = 1;
                    this.nextBtnTxt = 'Sign In';
                    this.formErrors.username = '';

                } else {
                    this.signinForm.controls.username.setErrors({'incorrect': true});
                    this.signinForm.controls.username.markAsTouched();
                    this.formErrors.username = 'Your Inspire Account could not be found';
                }

            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
            email);
    }


    goBackEmail() {
        this.nextClicked = false;
        this.displayMode = 0;
        this.nextBtnTxt = 'Next';
    }

    getFormEmail(): string {
        return this.signinForm.controls.username.value;
    }

    getErrorMsg(error: any): string {
        return error.error.results[0].message;
    }

    doSignUp() {
        this.router.navigate([AppConstants.WELCOME_ROUTE + '/' + AppConstants.SIGN_UP]);
    }

    onForgetPassword() {
        this.displayMode = 5;
        this.nextBtnTxt = 'Send';
        this.topWording = 'Forgot Password';
        this.description = 'It\'s okay ' + this.signinForm.controls.username.value + '. Give us your email, we will send you the reset link.';

    }


//    -----------------------------------------------------
    getDisplayMode(displayMode: number) {
        this.displayMode = displayMode;
        switch (displayMode) {
            case 4:
                this.nextBtnTxt = 'Save';
                break;

        }
    }

    getSigninCredentialsFromSocial(credentials: AuthCredentials) {

        this.doOnSignIn(credentials);
    }


}
