import { Component, OnInit } from '@angular/core';
import { AppAuthGuard } from '../../app.authguard';
import { WelcomeEventBus } from './welcome.event.bus';
import { InternalAuthService } from '../../services/auth.service';
import { UserManagementService } from '../../services/user.management.service';
import { takeWhile } from 'rxjs/operators';
import { AuthCredentials } from '../../models/authdata/auth.credentials';
import { SocialSigninCredentials } from '../../models/authdata/social.signin.credentials';
import { INSAuthResponse } from '../../models/responce/ins.auth.response';
import { INSException } from '../../models/ins.exception';
import {INSUserProfile, ScopeDataBlocks} from '../../models/user/ins.user.profile';
import { INSMessage } from '../../models/ins.message';
import { LoaderService } from '../../services/loader.service';
import { AlertModel } from '../../models/internal/alert.model';
import { NotificationEventBus } from '../../notification.event.bus';
import { AppConstants } from '../../app.constants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

    alive = true;

    constructor(private authGuard: AppAuthGuard,
                private authService: InternalAuthService,
                private router: Router,
                private userManagementService: UserManagementService,
                private welcomeEventBus: WelcomeEventBus,
                private notificationEventBus: NotificationEventBus,
                private loaderService: LoaderService) {
    }

    ngOnInit() {

        this.welcomeEventBus.signinCredsAdded$.pipe(takeWhile(() => this.alive))
            .subscribe(signinCredentials => this.doOnSignIn(signinCredentials));
        this.welcomeEventBus.socialSigninCredsAdded$.pipe(takeWhile(() => this.alive))
            .subscribe(socialSigninCredentials => this.doOnSocialSignin(socialSigninCredentials));
        this.welcomeEventBus.signupUserAdded$.pipe(takeWhile(() => this.alive))
            .subscribe(signUpUser => this.doOnSignUp(signUpUser));
        this.authGuard.doIfCurrentUserFound();


    }

  doOnSignIn(signInCredentials: AuthCredentials) {
    this.authService.getAuth(
      (auth: INSAuthResponse) => {
        auth.provider = signInCredentials.source;
        auth.username = signInCredentials.username;
        this.onSignInSuccess(auth);
      },
      (insException: INSException) => {
        this.welcomeEventBus.addSignUpMessage(insException.message);
      },
      signInCredentials);
  }

  private onSignInSuccess(auth: INSAuthResponse) {
    this.authGuard.saveCurrentUserAuth(auth);
    if (auth.rememberMe) {
      this.authGuard.saveCurrentUserAuthToLocalStorage(auth);
    }
    this.userManagementService.loadUserProfile(
      (userProfile: ScopeDataBlocks[]) => {
        this.authGuard.saveCurrentUser(this.makeUserProfile(userProfile));
        if (auth.rememberMe) {
          this.authGuard.saveCurrentUserToLocalStorage(this.makeUserProfile(userProfile));
        }
          this.notificationEventBus.updateAlertContent(new AlertModel('success', 'signed in'));

          this.authGuard.redirectToProfile(); // TODO check the role and do redirect
          this.router.navigate([AppConstants.HOME_ROUTE]);
      },
      (insException: INSException) => {
        this.welcomeEventBus.addSignUpMessage(insException.message);
          this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
          this.authGuard.redirectToWelcome();
      });
  }


    makeUserProfile(scopeData: ScopeDataBlocks[]) {
        let userProfile : INSUserProfile = new INSUserProfile();
        for (const data of scopeData) {
            switch (data.scopeCode) {
                case 'FULL_NAME' :
                    userProfile.fullName = data.data;
                    break;
                case 'BIRTHDAY' :
                    userProfile.birthday = data.data;
                    break;
                case 'EMAIL' :
                    userProfile.email = data.data;
                    break;

            }
        }
        return userProfile;
    }
  doOnSocialSignin(socialSigninCredentials: SocialSigninCredentials) {

  }

  doOnSignUp(signUpUser: AuthCredentials) {
    this.userManagementService.saveUser(
      (ack: INSMessage) => {
        this.authGuard.redirectToWelcome();
      },
      (insException: INSException) => {
          this.welcomeEventBus.addSignUpMessage(insException.message);
          this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
      },
      signUpUser);
  }



}
