import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NavBarItemModel} from './nav-bar-item.model';
import {isNullOrUndefined} from 'util';
import { Router} from '@angular/router';
import {AppUrlMapping} from '../../app.url.mapping';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit, OnChanges {

    @Input()
    navBarItems: NavBarItemModel[] = [];

    selectedItem: NavBarItemModel;

    constructor(private router: Router) {
        router.events.subscribe((val) => {
            let value: any = val;
            if (!isNullOrUndefined(value) && !isNullOrUndefined(value.url)) {
                this.selectTheCurrentNav(value.url);
            }
        });
    }

    selectTheCurrentNav(currentRoute: string) {
        let routes = currentRoute.trim().split('/');
        this.selectedItem = this.getNavBarItem(routes[routes.length - 1]);
    }

    getNavBarItem(tag: string) {
        for (const item of this.navBarItems) {
            if (item.routerLink === tag) {
                return item;
            }
        }
        return this.navBarItems[0];
    }

    changeMenu() {
        document.getElementsByClassName('mobile-menu')[0].classList.toggle('active-side-menu');
    }

    ngOnInit() {
        // this.selectTheCurrentNav(this.router.url);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes['navBarItems'])) {
            this.navBarItems = changes['navBarItems'].currentValue;
            if (this.navBarItems.length !== 0) {
                this.selectedItem = this.navBarItems[0];
                this.selectTheCurrentNav(this.router.url);
            }
        }
    }

    onNavBarSelect(selected: NavBarItemModel) {
        this.selectedItem = selected;

        this.router.navigate([AppUrlMapping.HOME_ROUTE + '/' + selected.routerLink], {});


    }
}
