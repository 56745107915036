import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoginProviderSettings} from './LoginProviderSettings';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidator} from '../../../../utill/validations/CustomValidator';
import {isNullOrUndefined} from 'util';
import {INSException} from '../../../../models/ins.exception';
import {AlertModel} from '../../../../models/internal/alert.model';
import {AppConstants} from '../../../../app.constants';
import {UserManagementService} from '../../../../services/user.management.service';
import {NotificationEventBus} from '../../../../notification.event.bus';
import {ValidationsManager} from '../../../../utill/validations/validations.manager';
import * as uuid from 'uuid';
import {INSUsersApp} from '../../../../models/app/ins.users.app';

@Component({
    selector: 'app-login-provider-settings',
    templateUrl: './login-provider-settings.component.html',
    styleUrls: ['./login-provider-settings.component.css']
})
export class LoginProviderSettingsComponent implements OnInit {

    @Input()
    providerSettings: LoginProviderSettings;
    @Input()
    isEdit: boolean;
    @Input()
    app: INSUsersApp;


    @Output()
    onSave: EventEmitter<LoginProviderSettings> = new EventEmitter<LoginProviderSettings>();
    @Output()
    onRemove: EventEmitter<LoginProviderSettings> = new EventEmitter<LoginProviderSettings>();
    providerSettingsForm: FormGroup;


    isShowImageModal = false;
    imageURL: string;
    reloadImage = false;

    constructor(private fb: FormBuilder,
                private userManagementService: UserManagementService,
                private notificationEventBus: NotificationEventBus,
                private validationManager: ValidationsManager,) {
    }

    ngOnInit() {
        this.generateForm();
    }



    handleFileUpdate(file: File) {
        this.uploadImage(file);
    }

    onCancelUpload(cancel: boolean) {
        if (cancel) {
            this.isShowImageModal = false;
            this.reloadImage = false;
        }
    }


    uploadImage(file: File) {
        let imageFile: File = file;
        this.reloadImage = true;

        if (imageFile) {
            const reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);

            this.userManagementService.uploadPicture(
                (message: any) => {
                    this.providerSettings.picture = message.imageUrl;
                    console.log(message.imageUrl);
                    this.isShowImageModal = false;
                    this.reloadImage = false;
                },
                (exception: INSException) => {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', exception.message));

                    this.isShowImageModal = false;
                },
                imageFile,
                this.randomFileNameForApp(this.app,this.providerSettings.provider,this.getFileExtension(file.name))
            );
        }
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    randomFileNameForApp(app:INSUsersApp,provider:string,extension:string) { // min and max included
        return app.appKey+"_"+provider+"_"+this.makeid(10)+"."+extension;
    }

    getFileExtension(fileName:string){
        return fileName.substr(fileName.lastIndexOf('.') + 1);
    }

    _handleReaderLoaded(readerEvt) {
        let binaryString = readerEvt.target.result;
        this.imageURL = 'data:image/png;base64,' + btoa(binaryString);
        this.providerSettings.picture = this.imageURL;
    }

    onChangeImage() {
        this.isShowImageModal = true;
        this.reloadImage = true;
    }

    generateForm() {
        let control = this.fb.control({value: '', disabled: this.providerSettings.limitUsers});
        control.setValidators(
            [Validators.required,
                CustomValidator.emailValidator
            ]
        );

        var displayName = this.providerSettings.displayName;
        if(this.providerSettings.provider!==AppConstants.LDAP){
            displayName = this.providerSettings.provider.toLowerCase();
            if(displayName!=null&&displayName!=="")displayName =  displayName[0].toUpperCase() + displayName.substr(1)
        }

        this.providerSettingsForm = this.fb.group({
            name: [{value: this.getFormattedValue(this.providerSettings.provider).toLowerCase(), disabled: true}],
            displayName: [{value: displayName, disabled: false}],
            restrict: [{value: this.providerSettings.limitUsers}],
            ldapServer: [{value: this.providerSettings.ldapServer!=null?this.providerSettings.ldapServer.split(":")[0]:"", disabled: false}],
            ldapServerPort: [{value: this.providerSettings.ldapServer!=null?this.providerSettings.ldapServer.split(":")[1]:"", disabled: false}],
            allowed: [{value: 'allowed'}],
            userBase: this.fb.array([control]),
        });


        if (!this.providerSettings.allowed) {
            this.providerSettingsForm.controls.allowed = new FormControl('restricted');
        } else {
            this.providerSettingsForm.controls.allowed = new FormControl('allowed');

        }
        this.providerSettingsForm.controls.restrict.setValue(this.providerSettings.limitUsers);
        this.setUsers();


        if(this.providerSettings.provider === 'LDAP') {
            this.providerSettingsForm.controls['ldapServer'].setValidators([Validators.required, CustomValidator.hostValidator]);
        }

    }

    setUsers() {
        for (let i = 0; i < this.providerSettings.userBase.length; i++) {
            if (i !== 0) {
                this.addControl();
            }
            this.userBase.controls[i].setValue(this.providerSettings.userBase[i]);
            this.userBase.controls[i].markAsTouched();
            this.userBase.controls[i].enable();
        }

    }

    getFormattedValue(value: string) {
        return value.replace(/['_']/g, ' ');

    }

    onChangeToggle() {
        this.userBase.controls[0].enable();
    }

    getProfilePic(imageName: string) {
        return this.validationManager.resolveAPIBase() + AppConstants.IMAGE_UPLOAD + imageName;
    }


    get userBase() {
        if (!isNullOrUndefined(this.providerSettingsForm.get('userBase'))) {
            return this.providerSettingsForm.get('userBase') as FormArray;
        }
    }

    get restrict() {
        if (!isNullOrUndefined(this.providerSettingsForm.get('restrict'))) {
            return this.providerSettingsForm.get('restrict').value;
        }
    }


    onRemoveUser(index: number) {
        this.userBase.removeAt(index);
    }

    onAddUsers() {
        this.addControlToForm();
    }

    isInvalidUser(index: number): boolean {
        if (!isNullOrUndefined(index)) {
            if (!isNullOrUndefined(this.userBase.controls[index])) {
                if (this.userBase.controls[index].status == 'INVALID') {
                    return !this.userBase.controls[index].untouched;
                } else {
                    return this.checkForDuplicates(this.userBase.controls[index].value, this.userBase.getRawValue());

                }
            }

        }
    }

    checkForDuplicates(email: string, array: string[]) {
        let count = 0;
        for (const mail of array) {
            if (email.trim() === mail.trim()) {
                count++;
            }
        }
        return count !== 1;
    }

    onSaveSettings(isCancel: boolean) {
        if(isCancel){
            this.providerSettingsForm.reset();
            this.onSave.emit(this.providerSettings);
        }else{
            if (this.providerSettingsForm.valid || !(this.providerSettingsForm.controls.restrict.value)) {
                this.setSettingsAndEmit();
            }
        }
    }


    setSettingsAndEmit() {
        this.providerSettings.limitUsers = this.providerSettingsForm.controls.restrict.value;
        this.providerSettings.displayName = this.providerSettingsForm.controls.displayName.value;

        let port = this.providerSettingsForm.controls.ldapServerPort.value?this.providerSettingsForm.controls.ldapServerPort.value:389;

        this.providerSettings.ldapServer = this.providerSettingsForm.controls.ldapServer.value+":"+ port;

        if (this.providerSettingsForm.controls.allowed.value === 'restricted') {
            this.providerSettings.allowed = false;
        } else {
            this.providerSettings.allowed = true;
        }
        if (this.userBase.getRawValue().length === 0 || (this.userBase.getRawValue().length === 1 && this.userBase.getRawValue()[0] === '')) {
            this.providerSettings.userBase = [];
        } else {
            this.providerSettings.userBase = this.userBase.getRawValue();
        }
        console.log(this.providerSettings, '+++++++++++++++++++');
        this.onSave.emit(this.providerSettings);
    }

    addControlToForm() {
        this.addControl();
    }

    addControl() {
        let control = this.fb.control('');
        control.setValidators(
            [
                Validators.required,
                CustomValidator.emailValidator

            ]
        );
        this.userBase.push(control);

    }

    removeLoginProvider() {
        this.onRemove.emit(this.providerSettings);
    }
}
