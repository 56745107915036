import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {INSUserProfile, ScopeDataBlocks} from '../../../models/user/ins.user.profile';
import {AlertModel} from '../../../models/internal/alert.model';
import {INSException} from '../../../models/ins.exception';
import {UserManagementService} from '../../../services/user.management.service';
import {NotificationEventBus} from '../../../notification.event.bus';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {INSMessage} from '../../../models/ins.message';
import {ConnectedProfiles} from '../../../models/profile/connected.profiles';
import {UserAddress} from '../../../models/user/user.address';
import {LoaderService} from '../../../services/loader.service';
import {INSUsersApp} from '../../../models/app/ins.users.app';
import {AppConstants} from '../../../app.constants';
import * as moment from 'moment';
import {Progress} from '../../../models/user/progress';
import {UserFullName} from '../../../models/user/user.fullName';
import {ValidationsManager} from '../../../utill/validations/validations.manager';

@Component({
    selector: 'app-personal-details',
    templateUrl: './personal-details.component.html',
    styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent implements OnInit {

    @ViewChild('firstName') firstName: ElementRef;
    @ViewChild('gender') dropdown: ElementRef;
    @ViewChild('status') statusDropdown: ElementRef;

    eleType: string = '';

    userProfile: INSUserProfile = new INSUserProfile();
    userProfileScopedData: ScopeDataBlocks[] = [];

    editProfile: FormGroup;

    imageURL: string;
    developerMode: boolean;
    editedProfile: INSUserProfile;
    selectedUserApp: INSUsersApp;
    loadData = false;
    isShowModal = false;
    isShowAppModal = false;
    displayIndex = 0;
    isShowImageModal = false;
    reloadImage = false;
    isShowConfirmationModal = false;
    maxDate = new Date();
    facebookLoginUser: ConnectedProfiles = new ConnectedProfiles();
    isFacebookConnected = false;
    inspireProfiles: Array<INSUsersApp> = [];
    profilePicUrl = '';
    imageUploadPage = new FormGroup(
        {
            profilePicture: new FormControl(null, [Validators.required,
                Validators.pattern(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)
            ]),

        }
    );
    progress: Progress;


    constructor(private userManagementService: UserManagementService,
                private notificationEventBus: NotificationEventBus,
                private validationManager: ValidationsManager,
                private cdRef: ChangeDetectorRef,
                private loaderService: LoaderService) {
        this.editedProfile = new INSUserProfile();
        this.developerMode = false;
    }

    ngOnInit() {
        this.generateForm();
        this.loadUserProfile();
    }

    getAllUsersApps() {
        this.userManagementService.loadUserLoggedApps(
            (appResp: INSUsersApp[]) => {
                this.inspireProfiles = appResp;
            },
            (insException: INSException) => {
                console.log(insException);
            }
        );
    }


    changeMenu() {
        document.getElementsByClassName('content')[0].classList.toggle('active-side-menu');
    }

    generateForm() {
        this.editProfile = new FormGroup({
            firstName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_ -]*$/)]),
            lastName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_ -]*$/)]),
            city: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_ -]*$/)]),
            state: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_ -]*$/)]),
            country: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_ -]*$/)]),
            streetAddress: new FormControl(null,),
            // dob: new FormControl(null, [Validators.required]),
            date: new FormControl(null, [Validators.required,
                Validators.minLength(1),
                Validators.maxLength(2),
                Validators.max(31),
                Validators.min(1)]),
            month: new FormControl(null, [Validators.required,
                Validators.minLength(1),
                Validators.maxLength(2),
                Validators.max(12),
                Validators.min(1)]),
            year: new FormControl(null, [Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                Validators.max(new Date().getFullYear() - 15),
                Validators.min(new Date().getFullYear() - 80)]),
            gender: new FormControl(null, [Validators.required]),
            status: new FormControl(null, [Validators.required])
        });

        this.editProfile.controls.gender.setValue('Male');
    }

    onSelectApp(inspireApp: INSUsersApp) {
        this.selectedUserApp = inspireApp;
        this.isShowAppModal = true;
    }

    onBackFromAppDetails(back: boolean) {
        this.isShowAppModal = false;
    }

    onRemoveAppClick(event, inspireApp) {
        event.stopPropagation();
        this.selectedUserApp = inspireApp;
        this.isShowConfirmationModal = true;
        this.isShowAppModal = false;
    }

    onRemoveAppConfirm() {
        this.deleteConsent();
    }

    loadUserProfile() {
        this.loaderService.showLoader();
        this.userManagementService.loadUserProfile(
            (userProfile: ScopeDataBlocks[]) => {
                this.userProfileScopedData = userProfile;
                this.displayIndex = 0;
                this.isShowModal = false;
                this.isShowImageModal = false;
                this.reloadImage = true;
                console.log(this.userProfileScopedData, 'user profile');
                if (!isNullOrUndefined(userProfile)) {
                    this.makeUserProfile(userProfile);
                }
                this.getAllUsersApps();
                this.loaderService.hideLoader();
                setTimeout(() => {
                    this.reloadImage = false;
                }, 1000);

            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                this.loaderService.hideLoader();
            });
    }

    private makeEditData() {

    }


    deleteConsent() {
        this.userManagementService.deleteConsent(
            (appResp: INSMessage) => {
                this.isShowConfirmationModal = false;
                this.getAllUsersApps();
                this.inspireProfiles = [];
                this.notificationEventBus.updateAlertContent(new AlertModel('success', appResp.message));

            },
            (insException: INSException) => {
                console.log(insException);
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
            this.selectedUserApp.appKey
        );
    }

    makeUserProfile(scopeData: ScopeDataBlocks[]) {
        for (const data of scopeData) {
            switch (data.scopeCode) {
                case 'FULL_NAME' : {
                    if (!isNullOrUndefined(data.data)) {
                        if (this.userProfile.fullName == null) {
                            this.userProfile.fullName = new UserFullName();
                        }
                        this.userProfile.fullName.firstName = data.data.firstName;
                        this.userProfile.fullName.lastName = data.data.lastName;
                        this.editProfile.get('firstName').setValue(data.data.firstName);
                        this.editProfile.get('lastName').setValue(data.data.lastName);
                    }

                    break;
                }

                case 'BIRTHDAY' : {
                    this.userProfile.birthday = data.data;
                    let dob = new Date(this.userProfile.birthday);
                    this.editProfile.get('date').setValue(dob.getDate() < 10 ? '0' + dob.getDate() : dob.getDate());
                    this.editProfile.get('month').setValue(dob.getMonth() < 10 ? '0' + (dob.getMonth() + 1) : dob.getMonth() + 1);
                    this.editProfile.get('year').setValue(dob.getFullYear());
                    break;
                }

                case 'EMAIL' : {
                    this.userProfile.email = data.data;
                    break;
                }

                case 'ADDRESS' : {
                    if (this.userProfile.address === null) {
                        this.userProfile.address = new UserAddress();
                    }
                    if (!isNullOrUndefined(data.data)) {
                        if (!isNullOrUndefined(data.data.streetAddress)) {
                            this.userProfile.address.streetAddress = data.data.streetAddress;
                        }
                        if (!isNullOrUndefined(data.data.city)) {
                            this.userProfile.address.city = data.data.city;
                        }

                        if (!isNullOrUndefined(data.data.country)) {
                            this.userProfile.address.country = data.data.country;
                        }
                        if (!isNullOrUndefined(data.data.state)) {
                            this.userProfile.address.state = data.data.state;
                        }

                        this.editProfile.get('streetAddress').setValue(data.data.streetAddress);
                        this.editProfile.get('city').setValue(data.data.city);
                        this.editProfile.get('state').setValue(data.data.state);
                        this.editProfile.get('country').setValue(data.data.country);
                    }
                    break;
                }
                case 'GENDER' : {
                    this.userProfile.gender = data.data;
                    if (data.data === '' || data.data === null) {
                        this.editProfile.get('gender').setValue('Male');
                    } else {
                        this.editProfile.get('gender').setValue(data.data);
                    }
                    break;
                }
                case 'MARITAL_STATUS' : {
                    this.userProfile.status = data.data;
                    if (data.data === '' || data.data === null) {
                        this.editProfile.get('status').setValue('Single');
                    } else {
                        this.editProfile.get('status').setValue(data.data);
                    }
                    break;
                }
                case 'PROFILE_PICTURE' : {
                    this.userProfile.profilePicture = data.data;
                    this.profilePicUrl = this.getProfilePic(this.userProfile.profilePicture);
                    this.imageUploadPage.get('profilePicture').setValue(this.userProfile.profilePicture);
                    this.imageURL = this.userProfile.profilePicture;
                    break;
                }
                case 'DEVELOPER_MODE' : {
                    this.developerMode = data.data;
                    if (isNullOrUndefined(this.developerMode)) {
                        this.developerMode = false;
                    }
                    localStorage.setItem(AppConstants.DEVELOPER_STATUS, this.developerMode.toString());
                    break;
                }
                case 'CONNECTED_PROFILES' : {
                    this.userProfile.connectedProfiles = data.data;

                    if (!isNullOrUndefined(this.userProfile.connectedProfiles)) {
                        for (const profile of this.userProfile.connectedProfiles) {
                            if (profile.provider.toLowerCase() === 'facebook') {
                                this.facebookLoginUser = profile;
                                this.isFacebookConnected = true;
                            }
                        }
                    }
                    break;
                }


            }
        }
        this.loadData = true;
    }


    handleFileUpdate(file: File) {
        this.uploadImage(file);
    }

    onCancelUpload(cancel: boolean) {
        if (cancel) {
            this.isShowImageModal = false;
            this.reloadImage = false;
        }
    }

    uploadImage(file: File) {
        let imageFile: File = file;
        this.reloadImage = true;

        if (imageFile) {
            const reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);

            this.userManagementService.uploadPicture(
                (message: any) => {
                    this.editedProfile.profilePicture = message.imageUrl;
                    this.saveProfile(this.editedProfile, true);
                },
                (exception: INSException) => {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', exception.message));

                    this.isShowImageModal = false;
                },
                imageFile,
                sessionStorage.getItem(AppConstants.USER_ID)
            );
        }
    }

    _handleReaderLoaded(readerEvt) {
        let binaryString = readerEvt.target.result;
        this.imageURL = 'data:image/png;base64,' + btoa(binaryString);
        this.profilePicUrl = this.imageURL;
    }

    getAddress(address: UserAddress) {
        let oneLineAddress = '';
        if (!isNullOrUndefined(address.streetAddress) && address.streetAddress !== '') {
            oneLineAddress = address.streetAddress + ',';
        }
        if (!isNullOrUndefined(address.city) && address.city !== '') {
            oneLineAddress = oneLineAddress + address.city + ',';
        }

        if (!isNullOrUndefined(address.state) && address.state !== '') {
            oneLineAddress = oneLineAddress + address.state + ',';
        }

        if (!isNullOrUndefined(address.country) && address.country !== '') {
            oneLineAddress = oneLineAddress + address.country;
        }
        if (oneLineAddress === '') {
            return 'Not specified yet';
        }
        return oneLineAddress;
    }

    onChangeImage() {
        // this.displayIndex = 2;
        this.isShowImageModal = true;
        this.reloadImage = true;

    }

    getProfilePic(imageName: string) {
        return this.validationManager.resolveAPIBase() + AppConstants.IMAGE_UPLOAD + imageName;
    }

    onGoBackFromSocial(index) {
        if (!isNullOrUndefined(index)) {
            this.loadUserProfile();
        }
    }

    getAge(bdate) {
        if (bdate) {
            let today = new Date();
            let birthDate = new Date(bdate);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age + ' years old';
        }

        return 'Not specified yet';
    }

    onEdit(type: string) {
        this.displayIndex = 1;
        this.eleType = type;
    }

    isFocused(type: string): boolean {
        if (this.eleType == type) {
            this.eleType = '';
            return true;
        } else {
            return false;
        }
    }

    openGender() {
        this.dropdown.nativeElement.focus();
    }
    openStatus() {
        this.statusDropdown.nativeElement.focus();
    }

    onGoBack() {
        this.displayIndex = 0;
        // this.editProfile.reset();
    }

    onSaveProfile() {

        if (this.editProfile.status !== 'INVALID') {

            if (moment(this.editProfile.get('year').value + '-' + (this.editProfile.get('month').value).toString() + '-' + this.editProfile.get('date').value).isValid()) {
                // name
                this.editedProfile.fullName = new UserFullName();
                this.editedProfile.fullName.firstName = this.editProfile.get('firstName').value;
                this.editedProfile.fullName.lastName = this.editProfile.get('lastName').value;

                let dob = new Date(this.editProfile.get('year').value, this.editProfile.get('month').value - 1, this.editProfile.get('date').value);
                this.editedProfile.birthday = dob.toISOString();

                // address
                this.editedProfile.address = new UserAddress();

                this.editedProfile.address.streetAddress = this.editProfile.get('streetAddress').value;
                this.editedProfile.address.city = this.editProfile.get('city').value;
                this.editedProfile.address.country = this.editProfile.get('country').value;
                this.editedProfile.address.state = this.editProfile.get('state').value;

                this.editedProfile.gender = this.editProfile.get('gender').value;

                this.editedProfile.status = this.editProfile.get('status').value;

                console.log(this.editedProfile, 'profile data');
                this.saveProfile(this.editedProfile, false);
            } else {
                document.getElementById('bday').classList.add('input-text--invalid');
            }
        } else {
            this.showInvalidFields();
        }

    }

    onSaveProfilePicture() {
        if (this.imageUploadPage.status !== 'INVALID') {
            this.editedProfile = this.userProfile;
            this.editedProfile.profilePicture = this.imageUploadPage.get('profilePicture').value;
            this.saveProfile(this.editedProfile, false);
        } else {
            document.getElementById('profilePicture').classList.add('input-text--invalid');
        }
    }

    onCancelModel() {
        this.displayIndex = 0;
        this.isShowImageModal = false;
        this.isShowConfirmationModal = false;
        if (!isNullOrUndefined(this.userProfileScopedData)) {
            this.makeUserProfile(this.userProfileScopedData);
        }
    }

    showInvalidFields() {
        if (this.editProfile.controls.firstName.status === 'INVALID') {
            document.getElementById('fName').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.lastName.status === 'INVALID') {
            document.getElementById('lName').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.gender.status === 'INVALID') {
            document.getElementById('gndr').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.city.status === 'INVALID') {
            document.getElementById('city').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.state.status === 'INVALID') {
            document.getElementById('state').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.country.status === 'INVALID') {
            document.getElementById('country').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.month.status === 'INVALID') {
            document.getElementById('bday').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.year.status === 'INVALID') {
            document.getElementById('bday').classList.add('input-text--invalid');
        }
        if (this.editProfile.controls.date.status === 'INVALID') {
            document.getElementById('bday').classList.add('input-text--invalid');
        }
        if (!moment(this.editProfile.get('year').value + '-' + (this.editProfile.get('month').value).toString() + '-' + this.editProfile.get('date').value).isValid()) {
            document.getElementById('bday').classList.add('input-text--invalid');
        }

    }

    isInvalid(formGroup: FormGroup, formControl?: string): boolean {
        if (formControl == undefined) {
            return !(formGroup.status == 'VALID');
        } else {
            return formGroup.get(formControl).status == 'INVALID' && !formGroup.get(formControl).untouched;
        }
    }

    onKey() {
        document.getElementById('bday').classList.remove('input-text--invalid');
    }

    saveProfile(editedProfile: INSUserProfile, isImageUpload: boolean) {

        this.userManagementService.updateUserProfile(
            (appResp: INSMessage) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('success', appResp.message));
                this.editProfile.reset();
                document.getElementById('bday').classList.remove('input-text--invalid');
                if (!isImageUpload) {
                    this.loadUserProfile();
                } else {
                    this.displayIndex = 0;
                    this.isShowImageModal = false;
                    this.reloadImage = false;
                }


            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            editedProfile
        );
    }

    changeDeveloperMode(isDev: boolean) {

        this.userManagementService.enableDeveloperMode(
            (appResp: INSMessage) => {
                localStorage.setItem(AppConstants.DEVELOPER_STATUS, isDev.toString());
                this.notificationEventBus.updateAlertContent(new AlertModel('success', appResp.message));
                this.loadUserProfile();


            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            isDev
        );
    }

    onDeveloperStatusChange() {
        this.changeDeveloperMode(this.developerMode);

    }

    getUpdatedSignal(isUpdated: boolean) {
        this.loadData = false;
        if (isUpdated) {
            this.loadUserProfile();
        }
    }

}
