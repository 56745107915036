import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {WelcomeModule} from './modules/welcome/welcome.module';
import {MODULE_ROUTES_APP} from './app.routes';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, LoginOpt, SocialLoginModule} from 'angularx-social-login';
import {GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig} from 'ng-gapi';
import {HomeComponent} from './modules/home/home.component';
import {SharedModule} from './shared/shared.module';
import {ServicesModule} from './services/service.module';
import {HomeModule} from './modules/home/home.module';
import {ProfileComponent} from './modules/profile/profile.component';
import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';

const gapiClientConfig: NgGapiClientConfig = {
    client_id: '428708899085-rfkabuvbo4ku3kopi2mg301m9nmo3kpn.apps.googleurootsercontent.com',
    discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],
    scope: [].join(' ')
};
const fbLoginOptions = {
    scope: 'user_age_range,user_birthday,email,user_gender,user_posts,user_photos,user_location',
    return_scopes: true,
    enable_profile_selector: true,
    auth_type: 'rerequest'
};
const googleLoginOptions = {
    scope: 'profile email',
    return_scopes: true,
    enable_profile_selector: true,
    auth_type: 'rerequest'
};


// Configs
export function getAuthServiceConfigs() {
    const config = new AuthServiceConfig(
        [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider('1250823934935582' , fbLoginOptions),
            },
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider('344555107412-q4o05f71sjnr2t0u7cgpg9f96b2orak3.apps.googleusercontent.com' , googleLoginOptions),
            }
        ]
    );
    return config;
}


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ProfileComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(MODULE_ROUTES_APP, {useHash: true}),
        HttpClientModule,
        SocialLoginModule,
        HttpModule,
        SharedModule,
        WelcomeModule,
        HomeModule,
        ServicesModule,
    ],
    providers: [
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        },
        Location,
        {   provide: LocationStrategy,
            useClass: PathLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
