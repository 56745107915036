export class AppUrlMapping {

    public static readonly WELCOME_ROUTE: string = 'welcome';
    public static readonly HOME_ROUTE: string = 'home';
    public static readonly PROFILE_ROUTE: string = 'profile';


    public static readonly SIGN_IN: string = 'signin';
    public static readonly SIGN_UP: string = 'signup';

    public static readonly PERSONAL_DETAILS: string = 'account-info';
    public static readonly INTERESTS: string = 'interest';
    public static readonly ACTIVITY: string = 'activity';
    public static readonly PRIVACY: string = 'privacy';
    public static readonly APPLICATIONS: string = 'applications';

}