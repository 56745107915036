// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_HOST_IS_SAME: true,

  DEV_PROTOCOL:'https://',
  DEV_IDENTIFIER_PREFIX: '',
  DEV_IDENTIFIER_SUFFIX: '.dev.codegen.net',
  DEV_API_SUFFIX: '',
  DEV_API_PREFIX: 'api.',

  QA_PROTOCOL:'http://',
  QA_IDENTIFIER_PREFIX: '',
  QA_IDENTIFIER_SUFFIX: '.qa.codegen.net',
  QA_API_SUFFIX: '',
  QA_API_PREFIX: 'api.',

  STAGING_PROTOCOL:'https://',
  STAGING_IDENTIFIER_PREFIX: '',
  STAGING_IDENTIFIER_SUFFIX: '.codegen.net',
  STAGING_API_SUFFIX: '',
  STAGING_API_PREFIX: 'api.',

  PROD_PROTOCOL:'https://',
  PROD_IDENTIFIER_PREFIX: '',
  PROD_IDENTIFIER_SUFFIX: '.codegen.co.uk',
  PROD_API_SUFFIX: '',
  PROD_API_PREFIX: 'api.',

    EXT_API_HOST: 'http://localhost:8080' //put  external host with port if different from API_HOST_IS_SAME = false
    // API_BASE_URL: 'http://192.168.2.179:8060',

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
