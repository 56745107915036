import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {QuestionBase} from '../../models/form/question-base';


@Component({
    selector: 'app-question',
    templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent {
    @Input() question: QuestionBase<any>;
    @Input() form: FormGroup;

    get isValid() {
        console.log(this.question , 'my question')
        console.log(this.form , 'my form')
        return this.form.controls[this.question.key].valid;
    }
}