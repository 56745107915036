import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {INSUsersApp, RequiredConsent} from '../../../../models/app/ins.users.app';
import {isNullOrUndefined} from 'util';
import {ScopeCodeDet} from '../../../../models/user/scopeCodeDet';
import {UserManagementService} from '../../../../services/user.management.service';
import {AlertModel} from '../../../../models/internal/alert.model';
import {NotificationEventBus} from '../../../../notification.event.bus';
import {UserConsentsGrant} from '../../../../models/app/UserConsentsGrant';

@Component({
    selector: 'app-connected-apps-details',
    templateUrl: './connected-apps-details.component.html',
    styleUrls: ['./connected-apps-details.component.css']
})
export class ConnectedAppsDetailsComponent implements OnInit, OnChanges {

    @Input()
    app: INSUsersApp;

    @Output()
    onBackFromAppDetails: EventEmitter<boolean> = new EventEmitter<boolean>();

    consentList: Array<RequiredConsent> = [];
    scopeCodeList: Array<ScopeCodeDet> = [];

    updatingConsent: UserConsentsGrant;
    constructor(private  userManagementService: UserManagementService,private notificationEventBus: NotificationEventBus) {
    }

    ngOnInit() {
        this.getScopeCodes();
    }

    getScopeCodes() {
        this.userManagementService.getScopeCodes(
            (res: ScopeCodeDet[]) => {
                this.scopeCodeList = res;
                this.makeConsentList();

            },
            (insException: any) => {

            },
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes['app'])) {
            this.app = changes['app'].currentValue;

            this.makeConsentList();
        }
    }

    makeConsentList() {
        if (!isNullOrUndefined(this.app) && this.scopeCodeList.length !== 0) {
            for (const consent of this.app.allowedConsent) {
                let req = new RequiredConsent(consent, this.getRequiredScopeCode(consent).required);
                req.displayName = this.getScopeCodeDet(consent).displayName;
                req.selected = true;
                this.consentList.push(req);
            }
        }
    }

    getScopeCodeDet(scopeCode: string): ScopeCodeDet {
        for (const scope of this.scopeCodeList) {
            if (scope.scopeCode === scopeCode) {
                return scope;
            }
        }
    }

    getRequiredScopeCode(scopeCode: string) {
        for (const scope of this.app.requiredConsent) {
            if (scope.consent === scopeCode) {
                return scope;
            }
        }
    }

    onSaveChanges() {
        this.updatingConsent = new UserConsentsGrant(this.app.appKey , this.makeConsentArray());

        this.saveInterest();
    }

    makeConsentArray(){
        let consentStringList = [];
        for(const consent of this.consentList){
            if(consent.selected){
                consentStringList.push(consent.consent);
            }
        }
        return consentStringList;
    }
    onCancel() {
        this.onBackFromAppDetails.emit(true);
    }


    saveInterest() {
        this.userManagementService.updateUserConsents(
            (ack: any) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('success', ack.message));
                this.onBackFromAppDetails.emit(true);

            }, (err: any) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', err.message));

            }, this.updatingConsent);
    }
}
