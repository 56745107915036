export class Progress {

    type: string;
    jobId: string;
    noOfBuckets: number;
    noOfProcessedBuckets: number;


    constructor(noOfBuckets: number, noOfProcessedBuckets: number) {
        this.noOfBuckets = noOfBuckets;
        this.noOfProcessedBuckets = noOfProcessedBuckets;
    }
}




