import {Route} from '@angular/router';
import {SignInComponent} from './sign-in/sign-in.component';
import {AppUrlMapping} from '../../app.url.mapping';
import {WelcomeComponent} from './welcome.component';
import {SignUpComponent} from './sign-up/sign-up.component';


export const MODULE_ROUTES_APP: Route[] = [

    {
        path: AppUrlMapping.WELCOME_ROUTE,
        children: [
            {path: AppUrlMapping.SIGN_IN, component: SignInComponent},
            {path: AppUrlMapping.SIGN_UP, component: SignUpComponent},
            {path: '', redirectTo: AppUrlMapping.SIGN_IN, pathMatch: 'full'}

        ],
        component: WelcomeComponent
    }


]

export const MODULE_COMPONENTS = [
    SignInComponent,
    SignUpComponent

];