import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {INSUserProfile, ScopeDataBlocks} from '../models/user/ins.user.profile';
import {INSException} from '../models/ins.exception';
import {Observable} from 'rxjs';
import {INSResponse} from '../models/responce/ins.response';
import {takeWhile, map} from 'rxjs/operators';
import {INSMessage} from '../models/ins.message';
import {InsUserDataBlock} from '../models/user/ins.user.data-block';
import {InsInterestDet, ManualInterestData} from '../models/profile/ins.interest.det';
import {isNullOrUndefined} from 'util';
import {AuthCredentials} from '../models/authdata/auth.credentials';
import {INSAuthResponse} from '../models/responce/ins.auth.response';
import {InterestTag} from '../models/profile/InterestTag';
import {ScopeCodeDet} from '../models/user/scopeCodeDet';
import {InsUser} from '../models/user/ins.user';
import {INSUsersApp} from '../models/app/ins.users.app';
import {UserConsentsGrant} from '../models/app/UserConsentsGrant';
import {AppAuthGuard} from '../app.authguard';


@Injectable({
    providedIn: 'root'
})
export class UserManagementService extends BaseService {

    constructor(http: HttpClient ,authGuard: AppAuthGuard) {
        super(http , authGuard);
    }

    uploadPicture(successCallback: (message: INSMessage) => void,
                  errorCallback: (insException: INSException) => void, imageFile: File, name: string) {
        this.uploadPictureLocal(imageFile, name).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    loadUser(successCallback: (insUserProfile: InsUser) => void,
             errorCallback: (insException: INSException) => void) {
        this.loadUserLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }


    deleteConsent(successCallback: (message: INSMessage) => void,
                  errorCallback: (insException: INSException) => void, appKey: string) {
        this.deleteConsnetLocal(appKey).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error))
        );
    }

    private deleteConsnetLocal(app_key: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'consents?app-key=' + app_key;
        return this.deleteWithAuth<INSUsersApp>(apiUrl, new HttpParams()).pipe(
            map(d => this.resolveResponse(d)),
        );
    }


    loadUserLoggedApps(successCallback: (userApps: INSUsersApp[]) => void,
                       errorCallback: (insException: INSException) => void) {
        this.loadUserLoggedAppsLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeRes(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    private loadUserLocal(): Observable<INSResponse<InsUser>> {
        const apiUrl = '/users/me';
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    private loadUserLoggedAppsLocal(): Observable<INSResponse<INSUsersApp[]>> {
        const apiUrl = '/users/me/apps';
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    loadUserProfile(successCallback: (insUserProfile: ScopeDataBlocks[]) => void,
                    errorCallback: (insException: INSException) => void) {
        this.loadUserProfileLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeRes(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    getAllInterests(successCallback: (insUserProfile: any[]) => void,
                    errorCallback: (insException: INSException) => void) {
        this.getAllInterestsLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeRes(res)),
            error => errorCallback(new INSException(error.error))
        );
    }

    loadUserInterest(successCallback: (insUserProfile: InsInterestDet) => void,
                     errorCallback: (insException: INSException) => void) {
        this.loadUserInterestsLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    private loadUserProfileLocal(): Observable<INSResponse<ScopeDataBlocks[]>> {
        const apiUrl = '/users/me/profile';
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    private loadUserInterestsLocal(): Observable<INSResponse<InsInterestDet>> {
        const apiUrl = '/users/me/profile/interests';
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    updateUserProfile(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void,
                      editedProfile: INSUserProfile) {
        this.updateUserProfileLocal(editedProfile).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    enableDeveloperMode(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void,
                        isDeveloper: boolean) {
        this.enableDeveloperModeLocal(isDeveloper).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    private updateUserProfileLocal(editedProfile: INSUserProfile): Observable<INSResponse<INSMessage>> {
        const apiUrl = '/users/me/profile';
        return this.putWithAuth(apiUrl, editedProfile, new HttpParams());
    }

    private enableDeveloperModeLocal(isDeveloper: boolean): Observable<INSResponse<INSMessage>> {
        const apiUrl = '/users/me/profile/developer-mode?enable-developer-mode=' + isDeveloper.toString();
        let params = new HttpParams();
        params.set('enable-developer-mode', isDeveloper.toString());
        return this.putWithAuth(apiUrl, null, params);
    }


    saveUser(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, signupUser: AuthCredentials) {
        this.saveUserLocal(signupUser).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    saveOrUpdateUserInterest(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, interest: Array<ManualInterestData>) {
        this.saveOrUpdateInterestLocal(interest).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    updateUserConsents(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, interestData: UserConsentsGrant) {
        this.updateUserConsentsLocal(interestData).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    saveEmailAndSendVerificationLink(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, email: string, accessToken: string) {
        this.saveEmailAndSendVerificationLinkLocal(email, accessToken).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    sendForgetPassword(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, email: string, username: string) {
        this.sendForgetPasswordLocal(email, username).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    private saveEmailAndSendVerificationLinkLocal(email: string, accessToken: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'users/me/emails?access-token=' + accessToken;

        let emailData = {'email': email};
        return this.post<AuthCredentials>(apiUrl, emailData).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    saveEmailAndSendVerificationWithAuthLink(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, email: string, signinCredentials: AuthCredentials) {
        this.saveEmailAndSendVerificationLinkWithAuthLocal(email, signinCredentials).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    verifyEmail(successCallback: (message: INSMessage) => void, errorCallback: (insException: INSException) => void, email: string, appKey: string, verificationCode: string) {
        this.verifyEmailLocal(email, appKey, verificationCode).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    getScopeCodes(successCallback: (message: ScopeCodeDet[]) => void, errorCallback: (insException: INSException) => void) {
        this.getScopeCodesLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeRes(res)),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }

    getLoginProviders(successCallback: (message: string[]) => void, errorCallback: (insException: INSException) => void) {
        this.getLoginProvidersLocal().pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeRes(res)[0]),
            error => errorCallback(new INSException(this.getErrorMsg(error)))
        );
    }


    private sendForgetPasswordLocal(email: string, username: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'users/password/reset/requests';
        let params = new HttpParams();
        params = params.append('email', email);
        params = params.append('username', username);


        return this.get<AuthCredentials>(apiUrl, params).pipe(
            map(d => this.resolveResponse(d))
        );

    }

    private saveEmailAndSendVerificationLinkWithAuthLocal(email: string, auth: AuthCredentials): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'options/user/emails';

        let emailData = {
            'email': email,
            'userCredentials': auth
        };
        return this.post<AuthCredentials>(apiUrl, emailData).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private verifyEmailLocal(email: string, appKey: string, verificationCode: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'users/email-verification/' + appKey + '?email=' + email + '&verification-code=' + verificationCode;

        return this.get<AuthCredentials>(apiUrl, null).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private saveUserLocal(signUpUser: AuthCredentials): Observable<INSResponse<INSMessage>> {
        const apiUrl = 'users';

        return this.post<AuthCredentials>(apiUrl, signUpUser).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private saveOrUpdateInterestLocal(interestData: Array<ManualInterestData>): Observable<INSResponse<INSMessage>> {
        let manualInterest = {
            manualInterestData: interestData
        };
        const apiUrl = 'users/me/profile/interests';

        return this.postWithAuth<AuthCredentials>(apiUrl, manualInterest, new HttpParams()).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private uploadPictureLocal(imageFile: File, name: string): Observable<INSResponse<INSMessage>> {

        const apiUrl = 'resources/external-assets/images?image-name=' + name;

        return this.uploadWithAuth<AuthCredentials>(apiUrl, '', imageFile, new HttpParams()).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private updateUserConsentsLocal(interestData: UserConsentsGrant): Observable<INSResponse<INSMessage>> {

        const apiUrl = 'consents';

        return this.putWithAuth<UserConsentsGrant>(apiUrl, interestData, new HttpParams()).pipe(
            map(d => this.resolveResponse(d))
        );
    }

    private getScopeCodesLocal(): Observable<INSResponse<ScopeCodeDet[]>> {

        const apiURL = 'data/scopes';

        return this.get(apiURL, null);
    }

    private getLoginProvidersLocal(): Observable<INSResponse<ScopeCodeDet[]>> {

        const apiURL = 'resources/internal-assets/auth-providers';

        return this.get(apiURL, null);
    }


    protected getAllInterestsLocal(): Observable<INSResponse<any>> {
        const apiUrl = 'apps/interest-tags';
        return this.get(apiUrl, null);
    }

    resolveResponse(data: any): INSResponse<any> {
        this.httpResponse = data;
        return data;
    }

    getErrorMsg(error: any): string {
        if (!isNullOrUndefined(error.error.results)) {

            return error.error.results[0].message;
        }
        if (!isNullOrUndefined(error.error.message)) {

            return error.error.message;
        }
        return 'Unknown error';
    }

}
