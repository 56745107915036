import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertModel} from '../../../../../models/internal/alert.model';
import {UserManagementService} from '../../../../../services/user.management.service';
import {ScopeCodeDet} from '../../../../../models/user/scopeCodeDet';
import {NotificationEventBus} from '../../../../../notification.event.bus';
import {QuestionControlService} from '../../../../../services/question-control.service';
import {QuestionBase} from '../../../../../models/form/question-base';
import {TextboxQuestion} from '../../../../../models/form/question-textbox';
import {isNullOrUndefined} from 'util';
import {INSUsersApp, RequiredConsent} from '../../../../../models/app/ins.users.app';
import {INSException} from '../../../../../models/ins.exception';
import {INSMessage} from '../../../../../models/ins.message';
import {AppManagementService} from '../../../../../services/app.management.service';
import {AppConstants} from '../../../../../app.constants';
import {Router} from '@angular/router';
import {ValidationsManager} from '../../../../../utill/validations/validations.manager';
import {CustomValidator} from '../../../../../utill/validations/CustomValidator';
import {AuthCredentials} from '../../../../../models/authdata/auth.credentials';
import {UserConsentGrant} from '../../../../../models/responce/userConsentGrant';
import {LoginProviderSettings} from '../../login-provider-settings/LoginProviderSettings';

@Component({
    selector: 'app-add-new-app',
    templateUrl: './add-new-app.component.html',
    styleUrls: ['./add-new-app.component.css']
})
export class AddNewAppComponent implements OnInit, OnChanges {


    @Output()
    back: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    app: INSUsersApp;
    @Input()
    displayIndex: number;

    imageUploadPage: FormGroup;
    @ViewChild('name') name: ElementRef;
    showMore: boolean;

    constructor(private userManagementService: UserManagementService,
                private notificationEventBus: NotificationEventBus,
                private validationManager: ValidationsManager,
                private router: Router,
                private fb: FormBuilder,
                private appServices: AppManagementService,) {
    }

    createApp: FormGroup;
    signinForm: FormGroup;
    isShowImageModal: boolean = false;
    isShowPlay: boolean = false;
    domainCount = 0;
    formDisabled = false;
    accessDetails = null;
    transactionToken = '';
    originalImageUrl = '';
    domainList = [];
    consentList: Array<ScopeCodeDet>;
    loginProviderSettings: Array<LoginProviderSettings>;
    ldapLoginProviderSettings: Array<LoginProviderSettings>;
    selectedLoginProviderSettings: LoginProviderSettings;
    requiredConsentList: Array<RequiredConsent> = [];
    isShowLoginSettings: boolean = false;

    errorMsg = '';

    formErrors = {
        'username': '',
        'password': '',
    };
    validationMessages = {
        'username': {
            'required': 'Username required.',
            'pattern': 'Invalid username.',
        },
        'password': {
            'required': 'Password required.',
            'minlength': 'Password should contain at least 8 characters.'
        },

    };

    ngOnInit() {
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes['app'])) {
            this.app = changes['app'].currentValue;
            console.log(this.app, '-------------------');
        }
        if (!isNullOrUndefined(changes['displayIndex'])) {
            this.displayIndex = changes['displayIndex'].currentValue;
            switch (this.displayIndex) {
                case 0:
                    break;
                case 1: {
                    //view only
                    this.formDisabled = true;
                    this.getScopeCodes().then(() => {
                        this.getUsersApp(this.app.appKey);
                    });
                }
                    break;
                case 2: {
                    //create new
                    this.formDisabled = false;
                    this.getScopeCodes().then(() => {
                        this.generateForm();
                    });
                }
                    break;
                case 3: {
                    //edit flow
                    this.formDisabled = false;
                    this.getScopeCodes().then(() => {
                        this.getUsersApp(this.app.appKey);
                    });
                }
                    break;
            }
        }
    }

    getFormattedValue(value: string) {
        return value.replace(/['_']/g, ' ');

    }

    ongetAccessToken() {
        //
        if (this.signinForm.valid) {
            this.getGrantConsent();
        } else {
            this.showInvalidLoginFields();
        }
    }

    showInvalidLoginFields() {
        if (this.signinForm.controls.username.status === 'INVALID') {
            document.getElementById('email').classList.add('input-text--invalid');
            this.createApp.controls.name.markAsTouched();
        }
        if (this.signinForm.controls.password.status === 'INVALID') {
            document.getElementById('pass').classList.add('input-text--invalid');
            this.createApp.controls.name.markAsTouched();
        }
    }

    getTransactionToken() {
        this.appServices.getTransactionToken(
            (token: any) => {
                this.transactionToken = token.accessToken;
            },
            (insException: INSException) => {
                console.log(insException);
            },
            this.app.appKey, this.app.appSecret
        );
    }

    copyMessage(value: string, location: string) {

        const selBox = document.createElement('textarea');
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notificationEventBus.updateAlertContent(new AlertModel('success', location + ' copied to clipboard '));
    }

    private getGrantConsent() {
        let auth = new AuthCredentials();
        let cred = new UserConsentGrant();
        cred.appKey = this.app.appKey;
        let list = [];
        for (const con of this.requiredConsentList) {
            if (con.selected) {
                list.push(con.consent);
            }
        }
        cred.grantedConsents = list;
        cred.userCredentials = auth;
        auth.username = this.signinForm.controls.username.value;
        auth.password = this.signinForm.controls.password.value;
        this.appServices.grantConsentForApp(
            (token: any) => {
                this.accessDetails = token;
                this.getTransactionToken();

            },
            (insException: INSException) => {
                console.log(insException);
                this.errorMsg = insException.message;
                this.signinForm.reset();
            }, cred
        );
    }

    onClosePopUp() {
        this.isShowPlay = false;

    }


    changeMenu() {
        document.getElementsByClassName('content')[0].classList.toggle('active-side-menu');
    }

    getUsersApp(appKey: string) {
        this.appServices.getApp(
            (appResp: INSUsersApp) => {
                this.app = appResp;
                this.generateForm();
            },
            (insException: INSException) => {
                console.log(insException);
            },
            appKey
        );
    }

    regenerateSecret(appKey: string) {
        this.appServices.regenerateSecret(
            (appResp: INSUsersApp) => {
                this.createApp.controls.appSecret.setValue(appResp.appSecret);
            },
            (insException: INSException) => {
                console.log(insException);
            },
            appKey
        );
    }

    generateAppSecret() {
        this.regenerateSecret(this.app.appKey);

    }

    onPlay() {
        this.isShowPlay = true;
        this.requiredConsentList = JSON.parse(JSON.stringify(this.app.requiredConsent));
        for (const con of this.requiredConsentList) {
            con.selected = true;
        }
    }

    generateForm() {
        let control = this.fb.control({value: '', disabled: this.formDisabled},);
        control.setValidators(
            [Validators.required,
                CustomValidator.urlValidator
            ]
        );
        this.createApp = this.fb.group({
            name: [{value: '', disabled: this.formDisabled}, Validators.required],
            description: [{value: '', disabled: this.formDisabled}, Validators.required],
            appKey: [''],
            appSecret: [''],
            domains: this.fb.array([control])
        });
        this.signinForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.imageUploadPage = new FormGroup({
            profilePicture: new FormControl(null, Validators.compose(
                [
                    Validators.required,
                    Validators.pattern(/\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/g)
                ]
                )
            )
        });
        this.createApp.controls.name.setValue(this.app.appName);
        this.createApp.controls.appKey.setValue(this.app.appKey);
        this.createApp.controls.appSecret.setValue(this.app.appSecret);
        this.addToggleControls();
        this.getLoginProviders();


    }

    onRemoveDomain(index: number) {
        this.domains.removeAt(index);
        this.domainCount--;
    }

    handleFileUpdate(file: File) {
        this.uploadImage(file);
    }

    uploadImage(file: File) {
        let imageFile: File = file;
        let imageName = this.getImageName();

        if (isNullOrUndefined(imageName)) {
            this.notificationEventBus.updateAlertContent(new AlertModel('error', 'App name required'));
            this.isShowImageModal = false;
        } else {
            if (imageFile) {
                this.userManagementService.uploadPicture(
                    (message: any) => {
                        this.app.appImage = message.imageUrl;
                        this.isShowImageModal = false;

                        if (this.displayIndex === 3) {
                            this.updateUsersApp(this.app);
                        }
                    },
                    (exception: INSException) => {
                        this.notificationEventBus.updateAlertContent(new AlertModel('error', exception.message));

                        this.isShowImageModal = false;
                    },
                    imageFile,
                    imageName + '_' + sessionStorage.getItem(AppConstants.USER_ID)
                );
            }
        }
    }

    onCancelUpload(cancel: boolean) {
        if (cancel) {
            this.isShowImageModal = false;
        }
    }

    getImageName(): string {

        if (this.app.appName == '' || isNullOrUndefined(this.app.appName)) {
            return this.createApp.controls['name'].value;
        }
        return this.app.appName.replace(' ', '_');
    }

    addToggleControls() {

        for (const consent of this.consentList) {
            if (this.displayIndex !== 2) {
                let status = this.isConsentAvailable(consent);
                consent.required = false;
                consent.selected = false;
                switch (status) {
                    case 2: {
                        consent.required = false;
                        consent.selected = true;
                        break;
                    }
                    case 3: {
                        consent.required = true;
                        consent.selected = true;
                        break;
                    }
                }

            }
        }
        if (this.displayIndex === 3 || this.displayIndex === 1) {

            this.domainCount = 0;
            this.createApp.controls.description.setValue(this.app.description);
            for (let i = 0; i < this.app.domains.length; i++) {
                if (i !== 0) {
                    this.addControl();
                }
                this.domains.controls[i].setValue(this.app.domains[i]);
                this.domains.controls[i].markAsTouched();
                if (this.app.disabled) {
                    this.domains.controls[i].disable();
                }

            }
        }

        if (this.displayIndex === 1) {
            this.createApp.controls.name.disable();
            this.createApp.controls.description.disable();

        }
    }

    onCancelSettings(){

        this.isShowLoginSettings = false;
    }

    onSaveSettings(settings: LoginProviderSettings) {

        this.isShowLoginSettings = false;
        let flag = false;
        if (this.app.allowedLoginOptions.length !== 0) {
            for (let option of this.app.allowedLoginOptions) {
                if (option.provider === settings.provider) {
                    option = settings;
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                this.app.allowedLoginOptions.push(settings);
            }
        } else {
            this.app.allowedLoginOptions.push(settings);
        }
        // if (this.displayIndex === 3) {
        //     this.updateUsersApp(this.app);
        // }
    }

    isConsentAvailable(consent: ScopeCodeDet) {
        // status 1=>consent not available
        // 2=> consent ok not required
        // 3=> consent ok  required
        for (const con of this.app.requiredConsent) {
            if (con.consent === consent.scopeCode) {
                if (con.required) {

                    return 3;
                }
                return 2;
            }
        }
        return 1;
    }

    isInvalid(formGroup: FormGroup, formControl?: string): boolean {
        if (!isNullOrUndefined(formGroup)) {
            if (formControl == undefined) {
                return !(formGroup.status == 'VALID');
            } else {
                if (!isNullOrUndefined(formGroup.get(formControl))) {
                    return formGroup.get(formControl).status == 'INVALID' && !formGroup.get(formControl).untouched;
                }
            }
        }
    }

    isInvalidDomain(index: string): boolean {
        if (!isNullOrUndefined(index)) {
            if (!isNullOrUndefined(this.domains.controls[index])) {
                return this.domains.controls[index].status == 'INVALID' && !this.domains.controls[index].untouched;
            }
        }
    }


    onCreateApp() {
        if (this.createApp.valid) {
            window.scrollTo(0, 0);
            this.app.description = this.createApp.controls.description.value;
            this.app.appName = this.createApp.controls.name.value;
            let domains = [];
            for (let i = 0; i < this.domains.controls.length; i++) {
                domains.push(this.domains.controls[i].value);
            }
            this.app.domains = domains;
            let consents: Array<RequiredConsent> = [];
            for (const consent of this.consentList) {
                if (consent.selected) {
                    consents.push(new RequiredConsent(consent.scopeCode, consent.required));
                }
            }
            this.app.requiredConsent = consents;
            if (this.displayIndex === 3) {
                this.updateUsersApp(this.app);
            } else if (this.displayIndex === 2) {
                this.addUsersApp(this.app);
            }
        } else {
            this.showInvalidFields();

        }
    }


    showInvalidFields() {
        if (this.createApp.controls.name.status === 'INVALID') {
            document.getElementById('nameDiv').classList.add('input-text--invalid');
            this.createApp.controls.name.markAsTouched();
        }
        if (this.createApp.controls.description.status === 'INVALID') {
            document.getElementById('descriptionDiv').classList.add('input-text--invalid');
            this.createApp.controls.description.markAsTouched();
        }
        for (let q = 0; q < this.domains.controls.length; q++) {
            if (this.domains.controls[q].status === 'INVALID') {
                document.getElementById(q.toString()).classList.add('input-text--invalid');
                this.domains.controls[q].markAsTouched();

            }
        }

    }

    onCancel() {
        this.back.emit(true);
    }

    getScopeCodes() {
        this.domainCount = 0;
        return new Promise((resolve, reject) => {
            this.userManagementService.getScopeCodes(
                (res: ScopeCodeDet[]) => {
                    this.consentList = res;
                    resolve();

                },
                (insException: any) => {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                    reject(insException);
                },
            );
        });
    }

    getLoginProviders() {
        this.domainCount = 0;
        this.userManagementService.getLoginProviders(
            (res: string[]) => {
                this.generateProviderSettings(res);
                console.log(res, 'log prov');
            },
            (insException: any) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
        );
    }

    generateProviderSettings(providers: string[]) {
        this.loginProviderSettings = [];
        this.ldapLoginProviderSettings = [];

        //non LDAP providers
        for (let provider of providers) {
            if(provider === "LDAP") continue; //LDAP is handled seperately
            let current = this.checkForExistingProviders(provider);

            if (!isNullOrUndefined(current)) {
                this.loginProviderSettings.push(current);
            } else {
                this.loginProviderSettings.push(new LoginProviderSettings(provider, false));
            }
        }

        //LDAP providers
        this.ldapLoginProviderSettings =  this.app.allowedLdapLoginOptions;
        if(this.ldapLoginProviderSettings == null)this.ldapLoginProviderSettings = [];
    }

    onSelectLoginProvider(providerToggle: any, provider: LoginProviderSettings) {
        if (providerToggle.target.value === 'false') {
            this.isShowLoginSettings = true;
            provider.enabled = true;
            this.selectedLoginProviderSettings = provider;
        } else {

            if (this.displayIndex === 3) {
                if (this.app.allowedLoginOptions.length !== 0) {
                    for (let option of this.app.allowedLoginOptions) {
                        if (option.provider === provider.provider) {
                            option.enabled = !providerToggle.target.value;
                            break;
                        }
                    }
                }
                // this.updateUsersApp(this.app);
            }

        }
    }

    onToggleLdapLoginProvider(providerToggle: boolean, provider: LoginProviderSettings) {
        provider.enabled = providerToggle;
        console.log(provider.enabled);
    }



    checkForExistingProviders(provider: string) {
        for (const prov of this.app.allowedLoginOptions) {
            if (prov.provider === provider) {
                prov.enabled = prov.enabled;
                return prov;
            }
        }
        return null;
    }

    onAddMoreDomains() {
        this.addControlToForm();
        this.domainList.push(this.domainCount);
    }

    onAddNewLdapServerClicked() {
        let newLdapServerProviderSettings = new LoginProviderSettings(AppConstants.LDAP,false);
        newLdapServerProviderSettings.displayName = "Untitled";
        this.ldapLoginProviderSettings.push(newLdapServerProviderSettings);
        this.onLoginProviderSettings(newLdapServerProviderSettings)
    }


    addControlToForm() {
        this.addControl();
    }

    get domains() {
        if (!isNullOrUndefined(this.createApp.get('domains'))) {
            return this.createApp.get('domains') as FormArray;
        }
    }

    onLoginProviderSettings(provider) {
        this.isShowLoginSettings = !this.isShowLoginSettings;
        this.selectedLoginProviderSettings = provider;
    }

    addControl() {
        let control = this.fb.control({value: '', disabled: this.formDisabled});
        control.setValidators(
            [
                Validators.required,
                CustomValidator.urlValidator

            ]
        );
        this.domains.push(control);
    }

    onChangeImage() {
        this.isShowImageModal = true;
    }

    getImageUrl(image: string) {
        console.log(this.validationManager.resolveAPIBase() + AppConstants.IMAGE_UPLOAD + image);
        return this.validationManager.resolveAPIBase() + AppConstants.IMAGE_UPLOAD + image;
    }

    addUsersApp(newUsersApp: INSUsersApp) {
        this.appServices.saveApp(
            (appResp: INSMessage) => {
                this.back.emit(true);
            },
            (insException: any) => {

                if (insException.message.error.results[0].message.includes('existing app')) {

                    this.name.nativeElement.focus();

                }
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message.error.results[0].message));

                console.log(insException);
            },
            newUsersApp
        );
    }


    updateUsersApp(usersApp: INSUsersApp) {
        this.appServices.updateApp(
            (appResp: INSMessage) => {
                this.back.emit(true);
                this.isShowImageModal = false;
                this.notificationEventBus.updateAlertContent(new AlertModel('success', appResp.message));

            },
            (insException: INSException) => {

                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            usersApp,
            usersApp.appKey
        );
    }

    getProfilePic(imageName: string) {
        return this.validationManager.resolveAPIBase() + AppConstants.IMAGE_UPLOAD + imageName;
    }

    onRemoveLoginProvider(provider: LoginProviderSettings) {
        this.isShowLoginSettings = false;
        this.ldapLoginProviderSettings.splice( this.ldapLoginProviderSettings.indexOf(provider),1);
        this.notificationEventBus.updateAlertContent(new AlertModel('info', 'Please click Update app to confirm changes'));
    }
}
