import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {INSUsersApp} from '../../../models/app/ins.users.app';
import {AppManagementService} from '../../../services/app.management.service';
import {INSException} from '../../../models/ins.exception';
import {INSMessage} from '../../../models/ins.message';
import {AlertModel} from '../../../models/internal/alert.model';
import {NotificationEventBus} from '../../../notification.event.bus';
import {FAQQuestions} from '../../../models/FAQ-qustions';
import {UserManagementService} from '../../../services/user.management.service';
import {AppConstants} from '../../../app.constants';


@Component({
    selector: 'app-users-apps',
    templateUrl: './applications.html',
    styleUrls: ['./applications.css']
})
export class Applications implements OnInit {

    displayIndex: number;
    hideAddApp = true;
    createAppFrm: FormGroup;
    app: INSUsersApp;
    usersAppsArray: INSUsersApp[] = [];
    appDomains: string[] = [];
    isUpdateApp: boolean = false;
    appName: string = '';
    isShowModal: boolean = false;
    faqQuestions: Array<FAQQuestions>;
    isShowDeveloperModal = false;
    confirmDelete = false;
    viewOnly = false;
    appStatus = 0;

    devStatus = false;
    @ViewChild('appName') appNameFocus: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private appServices: AppManagementService,
                private userManagementService: UserManagementService,
                private notificationEventBus: NotificationEventBus) {
        this.displayIndex = 0;
        this.app = new INSUsersApp();
        this.devStatus = localStorage.getItem(AppConstants.DEVELOPER_STATUS) === 'true';
        this.createFAQs();
    }

    createFAQs() {
        this.faqQuestions = [];
        this.faqQuestions.push(new FAQQuestions('0', 'How do I integrate Inspire Connect to my site?', '     <div>\n' +
            '        <div>Its easy to integrate Inspire Connect to your site.</div>\n' +
            '        <ol>\n' +
            '            <li>\n' +
            '                <div>\n' +
            '                    Create an app here.\n' +
            '                </div>\n' +
            '            </li>\n' +
            '            <li>\n' +
            '                <div>\n' +
            '                    Copy your app key and app secret.\n' +
            '                </div>\n' +
            '            </li>\n' +
            '            <li>\n' +
            '                Then in your client project embed this code.\n' +
            '                <pre>\n' +
            '                <code>\n' +
            ' &lt;meta property=&quot;inspire-connect-client-app-key&quot; content=&quot;$&#123;inspire-connect-client-app-key&#125;&quot;&#47;&gt;\n' +
            ' &lt;script src=$&#123;api-base&#125;&#47;js&#47;jquery.min.js&gt;&lt;&#47;script&gt;\n' +
            ' &lt;div id=&quot;inspire-connect-sign-in-button&quot; data-callback=&quot;signinCallback&quot;&gt;&lt;&#47;div&gt;\n' +
            ' &lt;script src=&quot;$&#123;api-base&#125;&#47;scripts&#47;sign-in-button&quot;&gt;&lt;&#47;script&gt;\n' +
            '\n' +
            ' &lt;script&gt;\n' +
            '     function signinCallback&#40;data,status&#41;&#123;\n' +
            '        console.log&#40;data&#41;;\n' +
            '     &#125;\n' +
            ' &lt;/script&gt;\n' +
            '     </code>\n' +
            '                </pre>\n' +
            '            </li>\n' +
            '            <li>Replace the app key.</li>\n' +
            '        </ol>\n' +
            '\n' +
            '        <h5>*Remember to whitelist the URL\'s in which you are embedding the sign in button, in the domains section.</h5>\n' +
            '    </div>\n' +
            '\n' +
            '\n' +
            '</div>'));
        this.faqQuestions.push(new FAQQuestions('1', 'Why do I need to whitelist \'domains\' in application?',
            ' <div >When you are embedding the Inspire Connect button in your site, to avoid any security breaches we have introduced domains.</div>' +
            '<div>We restrict embedding the button in sites other than the whitelisted sites.</div>' +
            '<div></div>'));
        this.faqQuestions.push(new FAQQuestions('2', 'What are \'Consents\' in application?',
            '<div>These are information that we collect in the Inspire profile. You can request permission from the user to collect the information that you need. </div>' +
            '<div>Enable the toggle button if you think you need a specific information from the user and if your app can\'t function without that information make it Required, we\'ll request those information from the users </div>'));
    }

    ngOnInit() {
        this.buildAddAppFrm();
        this.getAllUsersApps();
    }

    onSelectFaq(faq: FAQQuestions) {
        if (document.getElementById(faq.id).classList.contains('show-details')) {
            document.getElementById(faq.id).classList.remove('show-details');
        } else {
            document.getElementById(faq.id).classList.add('show-details');
        }
    }

    private buildAddAppFrm() {
        this.createAppFrm = this.formBuilder.group({
            appName: ['', Validators.required]
        });
    }

    changeMenu() {
        document.getElementsByClassName('content')[0].classList.toggle('active-side-menu');
    }

    onAppSelect(app: INSUsersApp, selection: number) {
        if (selection == 1) {
            if (this.devStatus) {
                this.displayIndex = 1;
                this.app = app;
            } else {
                this.isShowDeveloperModal = true;
            }
        } else {
            this.displayIndex = 3;
            this.app = app;
        }
    }

    onChangeAppStatus(app: INSUsersApp) {
        this.app = app;
        this.changeAppStatus(app.appKey, !app.disabled, app.appName);
    }


    /* *********************************************** */

    addUsersApp(newUsersApp: INSUsersApp) {
        this.appServices.saveApp(
            (appResp: INSMessage) => {
                this.getAllUsersApps();
            },
            (insException: INSException) => {
                console.log(insException);
            },
            newUsersApp
        );
    }

    changeAppStatus(appKey: string, status: boolean, appname: string) {
        this.appServices.changeAppStatus(
            (appResp: INSMessage) => {
                this.getAllUsersApps();
                let msg = '';
                if (!status) {
                    msg = appname + ' is activated and ready to use.';
                } else {
                    msg = appname + ' is deactivated.';
                }
                this.notificationEventBus.updateAlertContent(new AlertModel('success', msg));
            },
            (insException: INSException) => {
                console.log(insException);
                if (insException.message.toLowerCase().includes('developer permission denied')) {
                    this.appStatus = 1;
                    this.isShowDeveloperModal = true;
                } else {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                }

            },
            appKey, status
        );
    }

    getAllUsersApps() {
        this.appServices.getApps(
            (appResp: INSUsersApp[]) => {
                this.usersAppsArray = appResp;
                console.log(this.usersAppsArray, 'apps');
            },
            (insException: INSException) => {
                console.log(insException);
            }
        );
    }

    deleteUsersAppConfirm(app) {
        if (this.devStatus) {
            this.confirmDelete = true;
            this.app = app;
        } else {
            this.isShowDeveloperModal = true;
        }
    }

    deleteUsersApp(usersApp: INSUsersApp) {
        this.appServices.deleteApp(
            (appResp: INSMessage) => {
                this.confirmDelete = false;
                this.notificationEventBus.updateAlertContent(new AlertModel('success', appResp.message));
                this.getAllUsersApps();
            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
            },
            usersApp.appKey
        );
    }


    onCreateNewApp() {
        this.isShowModal = true;

    }

    onCreateApp() {
        if (this.createAppFrm.controls.appName.valid) {

            if (this.checkForDuplicates(this.createAppFrm.controls.appName.value)) {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', 'You already have a ' + this.createAppFrm.controls.appName.value));
                this.createAppFrm.controls.appName.reset();

            } else {
                this.appName = this.createAppFrm.controls.appName.value;
                this.app = new INSUsersApp();
                this.app.appName = this.appName;
                this.isShowModal = false;
                this.displayIndex = 2;
            }


        } else {
            if (this.createAppFrm.controls.appName.status === 'INVALID') {
                document.getElementById('nameDiv').classList.add('input-text--invalid');
                this.createAppFrm.controls.appName.markAsTouched();
            }
        }

    }

    checkForDuplicates(appName: string) {
        for (const app of this.usersAppsArray) {
            if (app.appName === appName) {
                return true;
            }
        }
        return false;
    }

    isInvalid(formGroup: FormGroup, formControl?: string): boolean {
        if (formControl == undefined) {
            return !(formGroup.status == 'VALID');
        } else {
            return formGroup.get(formControl).status == 'INVALID' && !formGroup.get(formControl).untouched;
        }
    }

    onClosePopUp() {
        this.isShowModal = false;
        this.isShowDeveloperModal = false;
        this.confirmDelete = false;
        this.appStatus = -1;

        this.createAppFrm.reset();
    }

    getBackFromAddApp(event: boolean) {
        this.displayIndex = 0;
        this.createAppFrm.reset();
        this.getAllUsersApps();
    }


    changeDeveloperMode(isDev: boolean, status: number) {

        this.userManagementService.enableDeveloperMode(
            (appResp: INSMessage) => {
                localStorage.setItem(AppConstants.DEVELOPER_STATUS, isDev.toString());
                this.devStatus = isDev;
                this.displayIndex = 0;
                if (status == 2) {
                    this.usersAppsArray = [];
                }

                if (this.appStatus === 1) {
                    this.changeAppStatus(this.app.appKey, !this.app.disabled, this.app.appName);
                }
                this.isShowDeveloperModal = false;
                if (status == 2) {
                    this.addUsersApp(this.app);
                }
                this.getAllUsersApps();
                this.notificationEventBus.updateAlertContent(new AlertModel('success', appResp.message));
            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            isDev
        );
    }

}


