import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {DynamicFormQuestionComponent} from './dynamic-form-question/dynamic-form-question.component';
import {SocialMediaConnectionsComponent} from './social-media-connections/social-media-connections.component';
import {ImageUploadComponent} from './image-upload/image-upload.component';
import {TranslateModule} from '@ngx-translate/core';

export const MODULE_COMPONENTS_SHARED = [NavBarComponent, DynamicFormQuestionComponent, SocialMediaConnectionsComponent, ImageUploadComponent];

@NgModule({

    imports: [
        CommonModule
        , BrowserModule
        , FormsModule
        , ReactiveFormsModule
        , RouterModule
        , TranslateModule.forRoot()
    ],
    declarations: [MODULE_COMPONENTS_SHARED],
    exports: [MODULE_COMPONENTS_SHARED]
})

export class SharedModule {
}
