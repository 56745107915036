import {Component, Input, OnInit} from '@angular/core';
import {UserManagementService} from '../../../services/user.management.service';
import {InsUserDataBlock} from '../../../models/user/ins.user.data-block';
import {INSMessage} from '../../../models/ins.message';
import {INSException} from '../../../models/ins.exception';
import {AlertModel} from '../../../models/internal/alert.model';
import {ScopeDataBlocks} from '../../../models/user/ins.user.profile';
import {isNullOrUndefined} from 'util';
import {NotificationEventBus} from '../../../notification.event.bus';
import index from '@angular/cli/lib/cli';
import {
    DerivedInterestData, InsInterestDet, InterestTimeBucket,
    ManualInterestData
} from '../../../models/profile/ins.interest.det';
import {InterestTag} from '../../../models/profile/InterestTag';
import {LineChartSeries} from '../../../models/interest/LineChartSeries';
import {LineChartDataModel} from '../../../models/interest/LineChartDataModel';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-interests',
    templateUrl: './interests.component.html',
    styleUrls: ['./interests.component.css']
})
export class InterestsComponent implements OnInit {

    interestText: string = '';
    @Input()
    interestingAreas: string[] = [];


    userProfileScopedData: ScopeDataBlocks[] = [];

    derivedInterests: any;
    noData: boolean;
    newInterest: ManualInterestData;
    manualInterests: Array<ManualInterestData>;
    selectedManualInterest: ManualInterestData;
    selectedDerivedInterest: DerivedInterestData;
    interestTagList: Array<any>;
    displayIndex = 0;
    isShowModal: boolean = false;
    loadData = false;
    showInterestFlow = false;
    lineChartObject: LineChartDataModel;

    constructor(private userManagementService: UserManagementService, private notificationEventBus: NotificationEventBus) {
        this.manualInterests = [];
    }

    ngOnInit() {
        this.loadInterestTags();
        this.newInterest = new ManualInterestData();
    }

    /** ************************************************ */
    loadInterestTags() {
        this.userManagementService.getAllInterests(
            (res) => {
                this.interestTagList = res;
                this.loadInterest(true);
                console.log(this.interestTagList, 'tag list');
            }, (err) => {
                this.displayIndex = -1;
            }
        );
    }

    getSelectedManualInterest(manualInterst: ManualInterestData) {
        this.selectedManualInterest = manualInterst;
        this.displayIndex = 5;
    }

    getViewFlow(go: boolean) {
        if (!isNullOrUndefined(go)) {
            this.showInterestFlow = go;
        }
    }

    getSelectedSelectedInterest(derivedInterestData: DerivedInterestData) {
        this.selectedDerivedInterest = derivedInterestData;
        this.displayIndex = 5;
        this.lineChartObject = this.reformatLineChartData(this.selectedDerivedInterest.interestTimeBuckets);

    }

    onInterestAddBtnClick() {
        if (this.interestText !== '') {
            this.interestingAreas.push(this.interestText);
            this.interestText = '';
        }
        document.getElementById('interest').focus();
        this.addUsersInterest();
    }

    onInterestRemoveClick(interestingArea: string) {
        this.interestingAreas.splice(this.interestingAreas.indexOf(interestingArea), 1);
        this.addUsersInterest();
    }

    /** ************************************************ */

    addUsersInterest() {
        let dataBlock: InsUserDataBlock = {
            scopeCode: 'INTERESTS',
            data: this.interestingAreas
        };
    }


    loadInterest(isInit: boolean) {
        this.userManagementService.loadUserInterest(
            (interestDet: InsInterestDet) => {

                if (!isNullOrUndefined(interestDet)) {
                    this.derivedInterests = interestDet.derivedInterests;
                    this.manualInterests = interestDet.manualInterests;
                    if (!isInit) {
                        this.displayIndex = 0;
                        this.isShowModal = false;
                        this.loadData = true;
                        this.noData = false;
                    }
                } else {
                    this.noData = true;
                    this.displayIndex = -2;
                }

            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));
                this.isShowModal = false;
            });
    }

    changeMenu() {
        document.getElementsByClassName('content')[0].classList.toggle('active-side-menu');
    }

    onGoBackFromFlow(back: boolean) {
        this.displayIndex = 0;
    }

    updateInterest(successMsg) {
        let interestList = [];
        interestList.push(this.newInterest);
        this.userManagementService.saveOrUpdateUserInterest(
            (ack: any) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('success', successMsg));
                this.loadInterest(false);

            }, (err: any) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', err.message));

            }, interestList);
    }

    makeUserProfile(scopeData: ScopeDataBlocks[]) {
        for (const data of scopeData) {
            switch (data.scopeCode) {
                case 'INTERESTS' : {
                    if (!isNullOrUndefined(data.data)) {
                        this.derivedInterests = data.data;
                        this.loadData = true;
                    }

                    break;
                }
            }
        }
    }

    getInterestKeys() {
        return Object.keys(this.derivedInterests);
    }


    onAddNewInterest() {
        this.isShowModal = true;
    }

    getBack(event: number) {
        if (event < 0) {
            this.loadInterest(false);
        } else {
            if (!this.noData) {
                this.displayIndex = 0;
            }
            this.isShowModal = false;
        }
    }

    getEditSignal(isEdit: boolean) {
        this.displayIndex = 1;
    }

    getDeleteSignal(isDelete: boolean) {
        if (isDelete) {
            console.log(this.newInterest , 'rgdfgdfrgrdrgtgsgsdggts');
            this.newInterest.removed = true;
            this.updateInterest('Interest successfully deleted.');
        }
    }

    getSelectedManual(manual: ManualInterestData) {
        this.newInterest = manual;
    }

    reformatLineChartData(timeBucketList: InterestTimeBucket[]) {
        const datePipe = new DatePipe('en-US');
        let lineChartObject = new LineChartDataModel();
        lineChartObject.name = timeBucketList[0].origin;
        for (let bucket of timeBucketList) {
            const lineChartSeries = new LineChartSeries();

            const myFormattedFromDate = datePipe.transform(bucket.fromDate, 'shortDate');
            const myFormattedToDate = datePipe.transform(bucket.toDate, 'shortDate');

            lineChartSeries.name = myFormattedFromDate + ' - ' + myFormattedToDate;
            lineChartSeries.value = bucket.score;
            lineChartObject.series.push(lineChartSeries);
        }
        return lineChartObject;
    }

}
