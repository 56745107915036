import {UserFullName} from './user.fullName';
import {UserAddress} from './user.address';

export class INSUserProfile {

    fullName: UserFullName;
    email: string;
    birthday: string;
    address: UserAddress;
    gender: string;
    status: string;
    profilePicture: string;
    connectedProfiles: any;

    constructor() {
        this.fullName = null;
        this.email = null;
        this.birthday = null;
        this.address = null;
        this.gender = null;
    }
}


export class ScopeDataBlocks {

    scopeCode: string;
    data: any;

}


