

export class AppConstants {

    public static readonly WELCOME_ROUTE: string = 'welcome';
    public static readonly ADMIN_ROUTE: string = 'admin';
    public static readonly HOME_ROUTE: string = 'home';
    public static readonly SIGN_IN: string = 'signin';
    public static readonly SIGN_UP: string = 'signup';
    public static readonly PERSONAL_DETAILS: string = 'account-info';
    public static readonly INTERESTS: string = 'interest';
    public static readonly ACTIVITY: string = 'activity';
    public static readonly PRIVACY: string = 'privacy';
    public static readonly APPLICATIONS: string = 'applications';
    public static readonly INTEGRATIONS: string = 'integrations';
    public static readonly CURRENT_USER: string = 'currentUser';
    public static readonly SOCIAL_LINK: string = 'socialMediaLinks';
    public static readonly IMAGE_UPLOAD: string = '/resources/external-assets/images/';


    public static readonly USER: string = 'user';
    public static readonly ACCESS_TOKEN: string = 'accessToken';
    public static readonly EXPIRES_IN: string = 'expiresIn';
    public static readonly USER_ID: string = 'userId';
    public static readonly REFRESH_TOKEN: string = 'refreshToken';
    public static readonly PROVIDER: string = 'provider';
    public static readonly REMEMBER_ME: string = 'rememberMe';
    public static readonly EMAIL: string = 'email';
    public static readonly USERNAME: string = 'username';
    public static readonly DEVELOPER_STATUS: string = 'developerStatus';

    public static readonly LDAP: string = 'LDAP';

    // Twitter Callback URL
    public static readonly TWITTER_CALLBACK_URL: string = 'callback';
    // http://localhost:4200/callback

}
