import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ManualInterestData} from '../../../../../models/profile/ins.interest.det';
import {UserManagementService} from '../../../../../services/user.management.service';
import {AlertModel} from '../../../../../models/internal/alert.model';
import {NotificationEventBus} from '../../../../../notification.event.bus';
import {isNullOrUndefined} from 'util';
import {InterestTag} from '../../../../../models/profile/InterestTag';

@Component({
    selector: 'app-add-new-interest',
    templateUrl: './add-new-interest.component.html',
    styleUrls: ['./add-new-interest.component.css']
})
export class AddNewInterestComponent implements OnInit, OnChanges {
    @Input()
    interest: ManualInterestData;
    @Input()
    availableInterest: Array<ManualInterestData>;
    @Input()
    interestTagList: Array<string>;

    @Output()
    back: EventEmitter<number> = new EventEmitter<number>();

    interestSelect: string;
    showSuggestion: boolean;
    rate: number;

    interestRatingList = [];
    filteredTagList: Array<string>;

    constructor(private userManagementService: UserManagementService, private notificationEventBus: NotificationEventBus) {
        this.showSuggestion = false;
        this.rate = 4;
        this.interestRatingList = ['I like it', 'I\'m a mediocre fan', 'I\'m a fan', 'I\'m a huge fan', 'I\'m obsessed'];
        this.filteredTagList = [];
        this.interestTagList = [];
        // this.filteredTagList = ['Food lover', 'Tech-geek', 'Nature Lover', 'Wild life photographer'];
    }

    @HostListener('document:click', ['$event']) clickedOutsideTypeAhead($event) {
        this.showSuggestion = false;
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.interestSelect = '';

        if (!isNullOrUndefined(changes['interestTagList'])) {
            this.interestTagList = changes['interestTagList'].currentValue;
            this.filteredTagList = this.interestTagList.slice();
            console.log(this.interestTagList);
        }

        if (!isNullOrUndefined(changes['interest'])) {
            this.interest = changes['interest'].currentValue;
            if (!isNullOrUndefined(this.interest.interestCode) && this.interest.interestCode !== '') {
                this.interestSelect = this.interest.interestCode;
            }

        } else {
            this.interest = new ManualInterestData();
        }
    }


    ngOnInit() {
        this.interestSelect = '';
    }

    onAddInterest() {
        if (!isNullOrUndefined(this.interestSelect) && !isNullOrUndefined(this.interestSelect) && this.interestSelect !== '') {
            this.interest.interestCode = this.interestSelect;
            if (!this.checkAvailability(this.interest.interestCode)) {
                this.saveInterest();
            } else {
                this.interestSelect = '';
                this.notificationEventBus.updateAlertContent(new AlertModel('error', 'You have already added this interest.'));
            }
        } else {
            document.getElementById('typeAhead').classList.add('input-text--invalid');
        }
    }

    checkAvailability(code) {
        for (const avail of this.availableInterest) {
            if (avail.interestCode == code) {
                return true;
            }
        }
        return false;
    }

    onSearchInterestChange() {
        this.showSuggestion = true;
        this.filteredTagList = this.interestTagList.filter((e1) => {
            if (!isNullOrUndefined(this.interestSelect)) {
                return e1.toLowerCase().includes(this.interestSelect.trim().toLowerCase()) || e1.toLowerCase().includes(this.interestSelect.toLowerCase().trim());
            } else {
                return true;
            }
        });
    }

    onClickTypeAhead($event: Event) {

        this.showSuggestion = true;

        // will stop propagation on lower layers
        $event.stopPropagation();
        $event.preventDefault();
    }

    onSelectOption(selected: string, $event: Event) {
        this.interestSelect = selected;
        this.interest.interestCode = selected;
        this.showSuggestion = false;
        document.getElementById('typeAhead').classList.remove('input-text--invalid');

        // will stop propagation on lower layers
        $event.stopPropagation();
        $event.preventDefault();
    }

    onGoBack() {
        this.interestSelect = '';
        this.back.emit(0);
    }

    saveInterest() {
        let interestList = [];
        if (this.isAnAvailableInterest(this.interest.interestCode)) {
            interestList.push(this.interest);
            this.userManagementService.saveOrUpdateUserInterest(
                (ack: any) => {
                    this.notificationEventBus.updateAlertContent(new AlertModel('success', ack.message));
                    this.interestSelect = '';
                    this.back.emit(-1);

                }, (err: any) => {
                    this.notificationEventBus.updateAlertContent(new AlertModel('error', err.message));

                }, interestList);
        } else {
            this.interestSelect = '';
            this.notificationEventBus.updateAlertContent(new AlertModel('warning', 'Please select an interest from our list.'));

        }
    }

    private isAnAvailableInterest(interest) {
        if (this.interestTagList.indexOf(interest) > -1) {
            return true;
        }
        return false;
    }

    getTagName(name: string) {
        let words = name.split(/(?=[A-Z])/);
        let interestName = '';
        for (let word of words) {
            interestName = interestName + word + ' ';
        }
        return interestName;
    }
}




