import {environment} from '../../../environments/environment';


export class INSSocialMediaType {
    socialMediaType: string;
    interestBucketSize: string;
    fromDate: Date;
    toDate: Date;
    accessToken: string;
    accessTokenSecret: string;
    notificationUrl: string;

    protected resolveAPIBase(): string {

        let apiBase:string;

        if (environment.API_HOST_IS_SAME) {

            let hostname = window.location.hostname;

            if (hostname.startsWith(environment.DEV_IDENTIFIER_PREFIX) && hostname.endsWith(environment.DEV_IDENTIFIER_SUFFIX)) {
                apiBase = environment.DEV_PROTOCOL + environment.DEV_API_PREFIX + hostname + environment.DEV_API_SUFFIX;
            } else if (hostname.startsWith(environment.QA_IDENTIFIER_PREFIX) && hostname.endsWith(environment.QA_IDENTIFIER_SUFFIX)) {
                apiBase = environment.QA_PROTOCOL + environment.QA_API_PREFIX + hostname + environment.QA_API_SUFFIX;
            } else if (hostname.startsWith(environment.STAGING_IDENTIFIER_PREFIX) && hostname.endsWith(environment.STAGING_IDENTIFIER_SUFFIX)) {
                apiBase = environment.STAGING_PROTOCOL + environment.STAGING_API_PREFIX + hostname + environment.STAGING_API_SUFFIX;
            } else if (hostname.startsWith(environment.PROD_IDENTIFIER_PREFIX) && hostname.endsWith(environment.PROD_IDENTIFIER_SUFFIX)) {
                apiBase = environment.PROD_PROTOCOL + environment.PROD_API_PREFIX + hostname + environment.PROD_API_SUFFIX;
            }

        } else {
            apiBase = environment.EXT_API_HOST;
        }

        return apiBase;
    }
    constructor(socialMediaType: string, accessToken: string) {
        this.socialMediaType = socialMediaType;
        this.accessToken = accessToken;
        this.accessTokenSecret = '';
        this.interestBucketSize = 'WEEK';
        // this.notificationUrl = this.resolveAPIBase() + '/social-media/connections/notification';
        this.notificationUrl = 'http://172.16.4.42:8080/social-media/connections/notification';
        this.fromDate = new Date(2017, 1, 1);
        this.toDate = new Date();
    }
}


