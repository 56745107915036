import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { RouterModule } from '@angular/router';
import { MODULE_ROUTES_APP } from './welcome.routes';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WelcomeEventBus } from './welcome.event.bus';
import {ValidationsManager} from '../../utill/validations/validations.manager';
import {ServicesModule} from '../../services/service.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(MODULE_ROUTES_APP),
        FormsModule,
        ReactiveFormsModule,
        ServicesModule,
        SharedModule
    ],
    declarations: [WelcomeComponent, SignInComponent, SignUpComponent ],
    bootstrap: [WelcomeComponent],
    providers: [WelcomeEventBus , ValidationsManager
    ]
})
export class WelcomeModule {
}
