import {HttpModule} from '@angular/http';
import {getAuthServiceConfigs} from '../app.module';
import {NotificationEventBus} from '../notification.event.bus';
import {LoaderService} from './loader.service';
import {InternalAuthService} from './auth.service';
import {AppAuthGuard} from '../app.authguard';
import {ProfileService} from './profile.service';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {QuestionControlService} from './question-control.service';

@NgModule({
    imports: [
        HttpModule,
        HttpClientModule
    ],

    providers: [
        AppAuthGuard,
        InternalAuthService,
        LoaderService,
        ProfileService,
        QuestionControlService,
        NotificationEventBus
    ]
})

export class ServicesModule {
}
