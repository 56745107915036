import {Component, EventEmitter, HostListener, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AuthService} from 'angularx-social-login';
import {SocialUser} from 'angularx-social-login';
import {FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import {INSSocialMediaType} from '../../../../models/profile/ins.social.media.type';
import {ProfileService} from '../../../../services/profile.service';
import {INSMessage} from '../../../../models/ins.message';
import {INSException} from '../../../../models/ins.exception';
import {NotificationEventBus} from '../../../../notification.event.bus';
import {AlertModel} from '../../../../models/internal/alert.model';
import {INSUserProfile, ScopeDataBlocks} from '../../../../models/user/ins.user.profile';
import {ConnectedProfiles, ProfileBuildingDet} from '../../../../models/profile/connected.profiles';
import {UserManagementService} from '../../../../services/user.management.service';
import {isNullOrUndefined} from 'util';
import {AppConstants} from '../../../../app.constants';
import {ValidationsManager} from '../../../../utill/validations/validations.manager';
import {AuthCredentials} from '../../../../models/authdata/auth.credentials';
import {SocialMediaLink} from '../../../../models/user/social.media.link';
import {AppAuthGuard} from '../../../../app.authguard';
import {Progress} from '../../../../models/user/progress';
import {LoaderService} from '../../../../services/loader.service';
import * as moment from 'moment';

@Component({
    selector: 'app-social-profile-link',
    templateUrl: './social-profile-link.component.html',
    styleUrls: ['./social-profile-link.component.css']
})
export class SocialProfileLinkComponent implements OnInit, OnChanges {


    gAuth: gapi.auth2.GoogleAuth;

    @Input()
    userProfile: INSUserProfile = new INSUserProfile();
    @Input()
    userProfileScopedData: ScopeDataBlocks[] = [];
    @Input()
    isDisplay: boolean = false;


    @Output()
    updated: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    back: EventEmitter<number> = new EventEmitter<number>();


    facebookLoginUser: ConnectedProfiles = new ConnectedProfiles();
    twitterLoginUser: ConnectedProfiles = new ConnectedProfiles();
    instaLoginUser: ConnectedProfiles = new ConnectedProfiles();
    googleLoginUser: ConnectedProfiles = new ConnectedProfiles();

    isFacebookConnected = false;
    facebookPending: Progress;
    instaPending: Progress;
    twitterPending: Progress;
    googlePending: Progress;
    isTwitterConnected = false;
    isInstaConnected = false;
    isGoogleConnected = false;
    callCount = 0;
    progressCount = 0;
    isShowModal = false;

    constructor(private socialAuthService: AuthService,
                private profileService: ProfileService,
                private ngZone: NgZone,
                private notificationEventBus: NotificationEventBus,
                private appAuthGuard: AppAuthGuard,
                private validationManager: ValidationsManager,
                private loaderService: LoaderService,
                private userManagementService: UserManagementService) {

    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!isNullOrUndefined(changes['userProfile'])) {
            this.userProfile = changes['userProfile'].currentValue;

            if (!isNullOrUndefined(this.userProfile.connectedProfiles)) {
                this.setConnectedProfiles();
            }

        }
        if (!isNullOrUndefined(changes['userProfileScopedData'])) {
            this.userProfileScopedData = changes['userProfileScopedData'].currentValue;

        }

    }

    setConnectedProfiles(){
        for (const profile of this.userProfile.connectedProfiles) {
            if (profile.provider.toLowerCase() === 'facebook') {
                this.isFacebookConnected = true;
                if(this.facebookLoginUser == null || moment(this.facebookLoginUser.lastConnected).isBefore(profile.lastConnected)){
                    this.facebookLoginUser = this.cloneMe(profile);
                }
            }
            if (profile.provider.toLowerCase() === 'twitter') {
                this.isTwitterConnected = true;
                if(this.twitterLoginUser == null || moment(this.twitterLoginUser.lastConnected).isBefore(profile.lastConnected)){
                    this.twitterLoginUser = this.cloneMe(profile);
                }
            }
            if (profile.provider.toLowerCase() === 'instagram') {
                this.isInstaConnected = true;
                if(this.instaLoginUser == null || moment(this.instaLoginUser.lastConnected).isBefore(profile.lastConnected)){
                    this.instaLoginUser = this.cloneMe(profile);
                }
            }
            if (profile.provider.toLowerCase() === 'google') {
                this.isGoogleConnected = true;
                if(this.googleLoginUser == null || moment(this.googleLoginUser.lastConnected).isBefore(profile.lastConnected)){
                    this.googleLoginUser = this.cloneMe(profile);
                }
            }
        }
        console.log(this.googleLoginUser);

        if (!isNullOrUndefined(this.facebookLoginUser.lastJobId)) {
            this.getSocialMediaProgress(this.facebookLoginUser.lastJobId, this.facebookLoginUser.provider);

        }
        if (!isNullOrUndefined(this.twitterLoginUser.lastJobId)) {
            this.getSocialMediaProgress(this.twitterLoginUser.lastJobId, this.twitterLoginUser.provider);
        }
        if (!isNullOrUndefined(this.instaLoginUser.lastJobId)) {
            this.getSocialMediaProgress(this.instaLoginUser.lastJobId, this.instaLoginUser.provider);
        }
        if (!isNullOrUndefined(this.googleLoginUser.lastJobId)) {
            this.getSocialMediaProgress(this.googleLoginUser.lastJobId, this.googleLoginUser.provider);
        }
    }

    connectApp() {
        this.isShowModal = true;
    }

    cloneMe(original) {
        return JSON.parse(JSON.stringify(original));
    }

    getSyncReq(credentials: AuthCredentials) {
        console.log(credentials);
        let processedProfile: ConnectedProfiles;
        switch (credentials.source) {
            case 'FACEBOOK':
                processedProfile = this.facebookLoginUser;
                break;
            case 'INSTAGRAM':
                processedProfile = this.instaLoginUser;
                break;
            case 'GOOGLE':
                processedProfile = this.googleLoginUser;
                break;
            case 'TWITTER' :
                processedProfile = this.twitterLoginUser;
                break;
        }
        if (this.isProfileEmpty(processedProfile)) {
            processedProfile.provider = credentials.source;
            processedProfile.name = credentials.username;
        }
        processedProfile.lastConnected = new Date();
        const socialTypeData = new INSSocialMediaType(credentials.source, credentials.accessToken);
        socialTypeData.accessTokenSecret = credentials.accessTokenSecret;
        let pbDet = new ProfileBuildingDet(socialTypeData, processedProfile);
        this.sendSocialMediaDetails(pbDet);
    }

    onRemoveSync(remove: boolean) {
        if (remove) {
            this.back.emit(1);
            this.updated.emit(true);
            this.isShowModal = false;
            this.loaderService.hideLoader();
        }
    }

    public isProfileEmpty(processedProfile: ConnectedProfiles) {
        if (processedProfile.name === '' && processedProfile.email === '' && processedProfile.id === '' && processedProfile.provider === '' && processedProfile.image === '') {
            return true;
        }
        return false;
    }

    public socialSignIn(socialPlatform: string, interval: number) {


        switch (socialPlatform) {
            case 'facebook':
                this.facebookSignIn();
                break;
        }

    }


    facebookSignIn() {
        let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;

        this.socialAuthService.signIn(socialPlatformProvider).then(
            (userData) => {
                this.ngZone.run(() => {
                    this.facebookLoginUser.email = userData.email;
                    this.facebookLoginUser.name = userData.name;
                    this.facebookLoginUser.image = userData.photoUrl;
                    this.facebookLoginUser.provider = userData.provider;
                    this.facebookLoginUser.lastConnected = new Date();
                    const socialTypeData = new INSSocialMediaType(userData.provider.toUpperCase(), userData.authToken);
                    console.log(userData.provider.toUpperCase());
                    let pbDet = new ProfileBuildingDet(socialTypeData, this.facebookLoginUser);
                    this.sendSocialMediaDetails(pbDet);
                });
            }
        );
    }

    onGoBack() {
        this.isShowModal = false;
        this.back.emit(1);

    }

    onSaveProfile() {
        for (const data of this.userProfileScopedData) {
            switch (data.scopeCode) {

                case 'CONNECTED_PROFILES' : {
                    if (!isNullOrUndefined(data.data)) {

                        let list: ConnectedProfiles[] = [];
                        list = JSON.parse(JSON.stringify(data.data));
                        list.push(this.facebookLoginUser);
                        data.data = data.data;

                    } else {
                        let list: ConnectedProfiles[] = [];
                        list.push(this.facebookLoginUser);
                        data.data = list;
                    }
                    break;
                }

            }
        }
        console.log(this.userProfileScopedData, 'profile data');
        this.saveProfile(this.userProfileScopedData);

    }

    saveProfile(dataBlock: ScopeDataBlocks[]) {
        let scopedData = {
            scopedDataList: dataBlock
        };
        this.userManagementService.updateUserProfile(
            (appResp: INSMessage) => {
                this.updated.emit(true);
            },
            (insException: INSException) => {
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            this.userProfile
        );
    }

    private sendSocialMediaDetails(socialData: ProfileBuildingDet) {
        this.profileService.linkSocialMedia(
            (ack: INSMessage) => {

                this.notificationEventBus.updateAlertContent(new AlertModel('success', ack.message));
                this.back.emit(1);
                this.updated.emit(true);
                this.isShowModal = false;
                this.loaderService.hideLoader();

                let link = new SocialMediaLink(ack.id, socialData.profileBuildingDetails.socialMediaType);
                // this.onSaveProfile();
                let links: Array<SocialMediaLink> = this.appAuthGuard.getLinkRequest();
                if (isNullOrUndefined(links)) {
                    links = [];
                }
                links.push(link);
                this.appAuthGuard.saveLinkRequest(links);
                this.getSocialMediaProgress(ack.id, socialData.profileBuildingDetails.socialMediaType);
            },
            (insException) => {
                this.loaderService.hideLoader();
                this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            socialData);

    }


    private getSocialMediaProgress(jobId: string, type: string) {
        this.callCount++;
        this.profileService.getSocialMediaProgress(
            (pro: Progress) => {
                console.log(pro, 'progress');
                pro.type = type;
                pro.jobId = jobId;
                switch (type) {
                    case 'INSTAGRAM':
                        this.instaPending = pro;
                        break;
                    case 'TWITTER':
                        this.twitterPending = pro;
                        break;

                    case 'FACEBOOK':
                        this.facebookPending = pro;
                        break;
                    case 'GOOGLE':
                        this.googlePending = pro;
                        break;
                }
                this.progressCount = pro.noOfProcessedBuckets;
                if (pro.noOfBuckets !== pro.noOfProcessedBuckets) {
                    if (this.callCount < 10) {
                        setTimeout(() => this.getSocialMediaProgress(jobId, type), 1000);
                    } else if (this.callCount > 10 && this.callCount < 100) {
                        setTimeout(() => this.getSocialMediaProgress(jobId, type), 50000);
                    } else if (this.callCount == 101) {
                        setTimeout(() => this.getSocialMediaProgress(jobId, type), 5000000);
                    } else {
                        if (this.progressCount !== 0) {
                            setTimeout(() => this.getSocialMediaProgress(jobId, type), 5000000);
                        }
                    }
                }
            },
            (insException) => {
                // this.notificationEventBus.updateAlertContent(new AlertModel('error', insException.message));

            },
            jobId);

    }


    startSignInFlow(): void {
        let tempStr: string;
        // this.profileService.twitterLogin().subscribe((response) => {
        //     tempStr = response['_body'];
        //     const a = tempStr.indexOf('&');
        //     const token = tempStr.substr(0, a);
        //     window.location.href = 'https://api.twitter.com/oauth/authenticate?' + token;
        // });
    }


}
