import {EventEmitter, Injectable} from '@angular/core';
import {AlertModel} from './models/internal/alert.model';

@Injectable()
export class NotificationEventBus {
  public alertUpdateEvent$: EventEmitter<AlertModel>;

  constructor() {
    this.alertUpdateEvent$ = new EventEmitter<AlertModel>();
  }

  updateAlertContent(element: AlertModel) {
    this.alertUpdateEvent$.emit(element);
  }
}
