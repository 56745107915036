import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {takeWhile, map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {INSMessage} from '../models/ins.message';
import {INSException} from '../models/ins.exception';
import {INSResponse} from '../models/responce/ins.response';
import {ProfileBuildingDet} from '../models/profile/connected.profiles';
import {Progress} from '../models/user/progress';
import {AppAuthGuard} from '../app.authguard';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseService {


    constructor(http: HttpClient,private appAuthguard: AppAuthGuard) {
        super(http,appAuthguard);
    }


    linkSocialMedia(successCallback: (insApp: INSMessage) => void,
                    errorCallback: (insException: INSException) => void, socialMediaType: ProfileBuildingDet) {
        this.linkSocialMediaLocal(socialMediaType).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }
    unSyncSocialMedia(successCallback: (insApp: INSMessage) => void,
                    errorCallback: (insException: INSException) => void, socialMediaType: string) {
        this.unSyncSocialMediaLocal(socialMediaType).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error.results[0].message))
        );
    }

    getSocialMediaProgress(successCallback: (insApp: Progress) => void,
                           errorCallback: (insException: INSException) => void, jobId: string) {
        this.getSocialMediaProgressLocal(jobId).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error?error.error.results[0].message:'Error'))
        );
    }

    private linkSocialMediaLocal(socialMediaType: ProfileBuildingDet): Observable<INSResponse<INSMessage>> {
        const apiUrl = '/social-media/connections/requests';
        return this.postWithAuth(apiUrl, socialMediaType, new HttpParams());
    }
    private unSyncSocialMediaLocal(type: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = '/social-media/connections/requests';
        let params = new HttpParams();
        params = params.set('social-media-type', type);
        return this.deleteWithAuth(apiUrl, params);
    }

    private getSocialMediaProgressLocal(jobId): Observable<INSResponse<Progress>> {

        const apiUrl = '/social-media/connections/requests/' + jobId + '/progress';
        return this.getWithAuth(apiUrl, new HttpParams());
    }

    linkSocialMediaWithoutAccessToken(successCallback: (insApp: INSMessage) => void,
                                      errorCallback: (insException: INSException) => void, socialMediaType: ProfileBuildingDet, accessToken: string) {
        this.linkSocialMediaWithoutAccessTokenLocal(socialMediaType, accessToken).pipe(takeWhile(() => this.alive)).subscribe(
            res => successCallback(this.makeAck(res)),
            error => errorCallback(new INSException(error.error?error.error.results[0].message:'Error'))
        );
    }

    private linkSocialMediaWithoutAccessTokenLocal(socialMediaType: ProfileBuildingDet, accessToken: string): Observable<INSResponse<INSMessage>> {
        const apiUrl = '/social-media/connections/requests?access-token=' + accessToken;
        return this.postWithoutAuth(apiUrl, socialMediaType, new HttpParams());
    }
}
