export class SocialMediaLink {

    jobId: string;
    type: any;


    constructor(jobId: string, type: any) {
        this.jobId = jobId;
        this.type = type;
    }
}




